(function () {
	angular.module('Plania').directive('userxwebprofile', function () {
		return {
			restrict: 'E',
			scope: {
				user: '=user',
				reload: '=reload',
				guid: '=guid',
				restrictEdit: '=restrictEdit'
			},
			controller: ['$scope', '$modal', 'Repository', 'TranslationService', controller],
			templateUrl: 'app/userXWebProfile/views/userXWebProfileDirective.html'
		};
	});
	function controller($scope, $modal, repository, translationService) {
		$scope.webProfiles = [];
		$scope.isChanging = false;

		$scope.treeOptions = {
			dropped: function (event) {
				if (event.dest.nodesScope.$parent.nodropEnabled) return;
				var draggedDown = event.source.index > event.dest.index;
				var startIndex = draggedDown ? event.dest.index : event.source.index;
				var endIndex = draggedDown ? event.source.index : event.dest.index;
				if (startIndex === endIndex) return;
				setTimeout(function () {
					reorderAndSave(startIndex, endIndex);
				}, 0);
			},
		};

		var getWebProfiles = function () {
			repository.GetPaginated(repository.apiData.userXWebProfile.url, 0, -1, { IndexPosition: 'asc' }, { PropertyFilter: [{ Property: 'GuidUser', operator: '=', value: $scope.user.Guid }] }).then(function (profileResult) {
				if (profileResult) {
					$scope.webProfiles = profileResult.List;
				}
				$scope.isChanging = false;
			}, function (error) {
				$scope.isChanging = false;
				repository.growl(error, 'danger');
			});
		};

		getWebProfiles();

		$scope.$watch('user.Guid', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			getWebProfiles();
		});

		var hasDuplicateIndexPositions = function () {
			var indexPositions = [];
			for (var i = 0; i < $scope.webProfiles.length; i++) {
				var indexPosition = $scope.webProfiles[i].IndexPosition;
				if (indexPositions.includes(indexPosition)) return true;
				indexPositions.push(indexPosition);
			}
			return false;
		};

		var reorderAndSave = function (startIndex, endIndex) {
			if ($scope.webProfiles.length === 0) return;

			if (startIndex === undefined || startIndex >= $scope.webProfiles.length)
				startIndex = 0;

			if (endIndex === undefined || endIndex <= startIndex)
				endIndex = $scope.webProfiles.length - 1;

			// Cleanup all if we have any duplicates
			if (hasDuplicateIndexPositions()) {
				startIndex = 0;
				endIndex = $scope.webProfiles.length - 1;
			}

			if (endIndex <= startIndex) return;

			var changeList = [];
			for (var i = startIndex; i <= endIndex; i++) {
				var webProfile = $scope.webProfiles[i];
				webProfile.IndexPosition = i;
				changeList.push(JSON.stringify({ Guid: webProfile.Guid, IndexPosition: webProfile.IndexPosition }));
			}

			$scope.isChanging = true;
			repository.patch(repository.apiData.userXWebProfile.url, null, changeList).then(function (result) {
				$scope.isChanging = false;
				repository.growl(translationService.translate('web-userXWebProfile-reorderAndSave-success', 'Webprofiler har blitt oppdatert'), "success");
			}, function (error) {
				$scope.isChanging = false;
			});
		};

		var getHighestIndexPosition = function () {
			if ($scope.webProfiles.length === 0) return 0;

			var maxWebProfile = _.max($scope.webProfiles, function (webProfile) {
				return webProfile.IndexPosition;
			});
			return maxWebProfile ? maxWebProfile.IndexPosition : 0;
		};

		$scope.addWebProfile = function () {
			if ($scope.isChanging) return;

			var alreadySavedGuids = _.map($scope.webProfiles, 'GuidWebProfile');

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg webprofil',
							columns: [
								{ Title: translationService.translate('web-webProfile-id'), Property: 'Id' },
								{ Title: translationService.translate('web-webProfile-description'), Property: 'Description' },
							],
							useObjects: false,
							url: repository.apiData.webProfile.url,
							sorting: { Id: 'asc' },
							filter: {},
							alreadySavedGuids: alreadySavedGuids
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var toSave = _.difference(selectedEntities, alreadySavedGuids);
				var i = getHighestIndexPosition();
				var userXWebProfiles = _.map(toSave, function (entity) {
					return JSON.stringify({ GuidUser: $scope.user.Guid, GuidWebProfile: entity, IndexPosition: i++ });
				});
				$scope.isChanging = true;
				repository.createMultiple(repository.apiData.userXWebProfile.url, userXWebProfiles).then(function (result) {
					repository.growl('Webprofiler har blitt lagt til', 'success');
					getWebProfiles();
				}, function (error) {
					$scope.isChanging = false;
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeWebProfile = function (profile) {
			if ($scope.isChanging) return;

			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-user-webProfile-message', 'profilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-user-webprofile-button-confirm', 'Ja, fjern profilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				$scope.isChanging = true;
				repository.deleteSingle(repository.apiData.userXWebProfile.url, profile.Guid).then(function (result) {
					$scope.isChanging = false;
					repository.growl(translationService.translate('user-profile-removed-message', 'Profilen har blitt fjernet'), 'success');
					_.remove($scope.webProfiles, function (webProfile) {
						return webProfile.Guid === profile.Guid;
					});
					reorderAndSave();
					swal.close();
				}, function (error) {
					$scope.isChanging = false;
					repository.growl(error, 'danger');
				});
			});
		};
	}
})();
