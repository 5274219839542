(function () {
	angular.module('Plania').controller('MassEditModalController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'EnumService',controller]);

	function controller($scope, $modalInstance, params, repository, translationService, enumService) {
		var entityPrefix = params.prefix;
		var apiData = _.find(repository.apiData, function (o) { return o.prefix === params.prefix; });

		$scope.datePickers = {};
		$scope.showPeriodicCleaningRecurrence = params.showPeriodicCleaningRecurrence;

		repository.getSingle(repository.apiData.registeredField.url, repository.commonService.prefix[entityPrefix]).then(function (result) {
			$scope.fieldRules = result;
			$scope.columns = [];

			params.columns.slice().forEach(function (column) {
				var columnFieldRule = _.find($scope.fieldRules, function (o) { return o.FieldName === column.Property; });

				if (column.Property === 'UpdatedDate' || column.Property === 'CreationDate') return;
				if (columnFieldRule && (columnFieldRule.Unique || columnFieldRule.NotEditableInListView)) return;

				if (column.PropertyType === 'date') {
					$scope.datePickers[column.Title] = false;
				}

				if (column.Property.indexOf('CleaningQuality.') >= 0) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningQuality';
					column.AutocompleteService = 'cleaningQuality';
				}

				if ((entityPrefix === repository.apiData.area.prefix) && (column.Property.indexOf('ResourceGroup.') >= 0)) {
					column.PropertyType = 'autocomplete';
					column.AutocompleteProperty = 'GuidCleaningTeam';
					column.AutocompleteService = 'resourceGroup';
				}

				if (column.PropertyType === 'recurrence') {
					column.RecurrenceRule = {};
					column.StartDate = new Date();
					if ($scope.showPeriodicCleaningRecurrence) {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Once',
							PeriodNumber: 1,
							PeriodUnit: 'Day'
						};
					} else {
						column.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Day'
						};
					}
					$scope.model = column;
				}

				$scope.columns.push(column);
			});
		});

		$scope.selectedRows = params.selectedRows;
		$scope.selectedColumns = [{}];

		$scope.getSelectedRowCaption = function (result) {
			var object = _.find(params.selectedRowsObjects, { Guid: result.Guid });
			if (object.Caption) return object.Caption;

			if (object.Area.Caption) return object.Area.Caption;
			if (object.Building.Caption) return object.Building.Caption;
		};

		$scope.save = function () {
			var editedRows = [];

			$scope.selectedRows.forEach(function (rowGuid) {
				var editedRow = { Guid: rowGuid };
				$scope.selectedColumns.forEach(function (column) {
					if (!column.model) {
						return;
					}

					if (column.model.PropertyType === 'recurrence') {
						if (column.model.RecurrenceRule) {
							enumService.setTimeUnitOnRecurrence(column.model.RecurrenceRule);

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.StartDate)
								column.model.RecurrenceRule.StartDate = new Date(column.model.RecurrenceRule.StartDate).toISOString();

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.EndDate)
								column.model.RecurrenceRule.EndDate = new Date(column.model.RecurrenceRule.EndDate).toISOString();

							if (column.model.RecurrenceRule && column.model.RecurrenceRule.Until)
								column.model.RecurrenceRule.Until = new Date(column.model.RecurrenceRule.Until).toISOString();

							editedRow.RecurrenceRule = JSON.stringify(column.model.RecurrenceRule);
						}

						if (column.model.StartDate) {
							editedRow.StartDate = new Date(column.model.StartDate).toISOString();
						}

						if (column.model.UntilDate) {
							editedRow.UntilDate = new Date(column.model.UntilDate).toISOString();
						}
					}
					else if (column.model.NewValue || column.model.NewValue === 0) {
						if (column.model.PropertyType === 'string' || column.model.PropertyType === 'number') {
							editedRow[column.model.Property] = column.model.NewValue;
						} else if (column.model.PropertyType === 'autocomplete') {
							editedRow[column.model.AutocompleteProperty] = column.model.newPropertyValue;
						} else if (column.model.PropertyType === 'date') {
							editedRow[column.model.Property] = new Date(column.model.NewValue).toISOString();
						}
					}
				});
				editedRows.push(JSON.stringify(editedRow));
			});

			if (entityPrefix === "AreaXCleaningTask") {
				repository.patch(apiData.url, null, editedRows).then(function (response) {
					$scope.updateResult = response;
					$modalInstance.close();
				}, function (error) {
					repository.growl(error, "danger");
				});
			} else {
				repository.createMultiple(repository.apiData.massUpdate.url, editedRows, 'Prefix=' + params.prefix).then(function (response) {
					$scope.updateResult = response;

					if (response.length === 0) {
						$modalInstance.close();
					}
				});
			}
		};

		$scope.columnWasSelected = function (column) {
			var index = $scope.columns.indexOf(column);
			$scope.columns.splice(index, 1);
			if ($scope.columns.length > 0) {
				$scope.selectedColumns.push({});
			}
		};

		$scope.removeSelectedColumn = function (column, index) {
			$scope.selectedColumns.splice(index, 1);

			if ($scope.columns.length === 0) {
				$scope.selectedColumns.push({});
			}

			$scope.columns.push(column);
		};

		$scope.cancel = function () {
			if ($scope.updateResult) {
				$modalInstance.close();
			} else {
				$modalInstance.dismiss('canceled');
			}
		};
	}
})();
