angular.module('Plania').directive('gantt', function () {
    return {
        restrict: 'E',
        scope: {
            tasks: '=tasks',
            viewMode: '=viewMode',
            filters: '=filters',
			count: '=count',
			columnData: '=columnData'
        },
        controller: ['$scope', controller],
        templateUrl: '/app/maintenancePlan/gantt.html'
    };

    function controller(scope) {
        var monthArray = ["Januar", "Februar", "Mars", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Desember"];

		var renderGantt = function () {
			$(".gantt").gantt({
				source: scope.tasks ? scope.tasks : [],
				columnData: !!scope.columnData ? scope.columnData : [],
				scale: scope.viewMode ? scope.viewMode : "days",
				scrollToToday: true,
				maxScale: "months",
				minScale: "days",
				itemsPerPage: scope.count,
				months: monthArray,
				dow: ["S", "M", "T", "O", "T", "F", "L"],
				waitText: "",
				useCookie: true,
				onItemClick: function (data) {
					var state = data.GuidWorkOrder ? 'workOrder.edit' : 'periodicTask.edit';
					var guid = data.GuidWorkOrder ? data.GuidWorkOrder : data.GuidPeriodicTask;
					scope.$root.navigation.go(state, { guid: guid, menuGuid: scope.$root.navigation.params.menuGuid });
				},
				onAddClick: function (dt, rowId) {

				},
				onRender: function (settings) {
					$(".gantt-task-controls, .gantt-task-footer").css('display', 'block');
					$(".gantt-task-controls").height($(".spacer").height());

					switch (settings.scale) {
						case "days":
							$(".row .date").height($(".dataPanel").height() - 24);
							$('.dataPanel').css("background-position", "24px 25px");
							break;
						case "weeks":
							$(".row .day").height($(".dataPanel").height() - 48);
							$('.dataPanel').css("background-position", "24px 25px");
							break;
						case "months":
							$(".row.year:not(.data-row)").height($(".dataPanel").height());
							$('.dataPanel').css("background-position", "24px 1px");
							break;
					}
				}
			});
		};


		scope.$watchGroup(['tasks'], function (newValues, oldValues, scope) {
			if (newValues === oldValues || !newValues) return;
			renderGantt();
		});
    }
});
