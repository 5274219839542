(function () {
	angular.module('Plania').controller('ActiveBuildingController', ['$scope', '$rootScope', 'Repository', 'CommonService', controller]);

	function controller($scope, $rootScope, repository, commonService) {
		$scope.count = 30;
		$scope.totalCount = 0;
		$scope.buildings = [];
		$scope.selectedBuilding = commonService.getFilterData().selectedBuilding;

		$scope.filter = {
			excludeBuilding: true,
			PropertyFilter: [
				{ Property: "IsTemplate", Operator: "=", Value: false }
			]
		};

		var columns = [
			'Id', 'Description', 'Estate.Guid', 'Estate.Id', 'Estate.Description',
		];

		$scope.getBuildings = function (setBuilding) {
			repository.GetPaginated(repository.apiData.building.url, 0, $scope.count, { id: "asc" }, $scope.filter, null, JSON.stringify(columns)).then(function (result) {
				if (result && result.List) {
					$scope.totalCount = result.TotalCount;
					$scope.buildings = result.List;
					if (result.List.length === 1 && setBuilding) {
						var building = result.List[0];
						commonService.setBuilding(building);
						$scope.selectedBuilding = { Guid: building.Guid, Name: building.Caption };
					}
				}
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		$scope.getBuildings();

		$scope.addMoreData = function () {
			$scope.count += 100;
			$scope.getBuildings();
		};

		$scope.changeBuilding = function (selected) {
			if (selected.Guid === $scope.selectedBuilding.Guid) {
				return;
			}

			commonService.setBuilding(selected);
			$rootScope.$broadcast($rootScope.events.newSelection, true);
		};

		$scope.setEmptyBuilding = function () {
			$scope.filter.dropdown = '';
			if (!$scope.selectedBuilding.Guid) {
				return;
			}

			commonService.setEmptyBuilding();
			$rootScope.$broadcast($rootScope.events.newSelection, false);
		};

		// #9573 fromEstate: SVV specific rule, setting estate should not automatically set building (if only 1 result)
		$scope.$on($scope.events.newSelection, function (event, reloadBuildings, fromEstate) {
			$scope.selectedBuilding = commonService.getFilterData().selectedBuilding;
			if ($scope.selectedBuilding.Guid === "" && reloadBuildings)
				$scope.getBuildings(fromEstate ? false : true);
		});
	}
})();
