(function () {
	angular.module('Plania').controller('CreateProjectController', ['$scope', 'Repository', 'RegisteredFieldService', 'TranslationService', 'ngTableParams', 'ListService', controller]);

	function controller($scope, repository, registeredFieldService, translationService, ngTableParams, ListService) {
		var userData = repository.authService.getUserData();
		$scope.activateAutocomplete = true;
		$scope.missingMandatoryProperties = [];
		$scope.creationMethod = ''; //template or blank

		var basicInformationFields = ['Description', 'StartDate', 'EndDate', 'Text'];
		var connectionsFields = ['GuidEstate', 'GuidBuilding', 'GuidArea', 'GuidCostCenter', 'GuidProjectCategory'];
		var responsiblesField = [];

		$scope.model = {
			isTemplate: false
		};

		if (userData.guidPerson) {
			$scope.model.ManagerPerson = { Caption: userData.personFirstName + ' ' + userData.personLastName };
			$scope.model.GuidManagerPerson = userData.guidPerson;
			$scope.model.OwnerPerson = { Caption: userData.personFirstName + ' ' + userData.personLastName };
			$scope.model.GuidOwnerPerson = userData.guidPerson;
		}

		registeredFieldService.getRegisteredField(repository.commonService.prefix.Project).then(function (result) {
			$scope.fieldRules = result;
		});

		$scope.steps = [			
			{ stepIndex: 0, name: 'startPage', hidden: true, },
			{ stepIndex: 1, name: 'chooseTemplate', hidden: false, title: translationService.translate('web-project-section-title-templates', 'Prosjektmal')},
			{ stepIndex: 2, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
			{ stepIndex: 3, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
			{ stepIndex: 4, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
		];

		$scope.currentStep = $scope.steps[0];

		$scope.hasError = function (field) {
			if ($scope.missingMandatoryProperties.includes(field) && !$scope.model[field])
				return true;
			else if ($scope.missingMandatoryProperties.includes(field) && $scope.model[field]) {
				_.remove($scope.missingMandatoryProperties, function (property) { return property === field; });
				return false;
			}

			return false;
		};

		var sectionIsValid = function (fields) {
			$scope.missingMandatoryProperties = [];
			fields.forEach(function (field) {
				if (_.find($scope.fieldRules, { DomainFieldName: field, Mandatory: true }) && !$scope.model[field]) {
					$scope.missingMandatoryProperties.push(field);
				}
			});

			if ($scope.missingMandatoryProperties.length > 0) {
				return false;
			} else {
				return true;
			}
		};

		$scope.isEndDateValid = function () {
			if ($scope.model.EndDate && $scope.model.EndDate < $scope.model.StartDate)
				return false;
			return true;
		};

		$scope.handleNextStep = function () {
			switch ($scope.currentStep.name) {
				case 'startPage':
					if ($scope.creationMethod === 'template') {

						$scope.steps = [							
							{ stepIndex: 0, name: 'startPage', hidden: true, },
							{ stepIndex: 1, name: 'chooseTemplate', hidden: false, title: translationService.translate('web-project-section-title-templates', 'Prosjektmal') },
							{ stepIndex: 2, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
							{ stepIndex: 3, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
							{ stepIndex: 4, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
						];

						$scope.currentStep = _.find($scope.steps, { name: 'chooseTemplate' });
					}

					else {
						$scope.steps = [							
							{ stepIndex: 0, name: 'startPage', hidden: true, },
							{ stepIndex: 1, name: 'basicInfo', hidden: false, title: translationService.translate('web-project-section-title-basicInformation', 'Basisinformasjon') },
							{ stepIndex: 2, name: 'connections', hidden: false, title: translationService.translate('web-project-section-title-connections', 'Tilknytninger') },
							{ stepIndex: 3, name: 'responsibles', hidden: false, title: translationService.translate('web-project-section-title-responsibles', 'Ansvarsfordeling') }
						];
						$scope.currentStep = _.find($scope.steps, { name: 'basicInfo' });
					}
						
					break;
				case 'chooseTemplate':
					$scope.currentStep = _.find($scope.steps, { name: 'basicInfo' });
					break;
				case 'basicInfo':
					if (sectionIsValid(basicInformationFields) && $scope.isEndDateValid()) {
						$scope.currentStep = _.find($scope.steps, { name: 'connections' });
					}
					break;
				case 'connections':
					if (sectionIsValid(connectionsFields)) {
						$scope.currentStep = _.find($scope.steps, { name: 'responsibles' });
					}
					break;
				case 'responsibles':
					if (sectionIsValid(responsiblesField)) {
						$scope.create();
					}
					break;
			}
		};

		$scope.handlePreviousStep = function () {
			for (var i = 1; i < $scope.steps.length; i++) {
				var step = $scope.steps[i];
				if (step.name === $scope.currentStep.name) {
					if ($scope.creationMethod !== 'template' && $scope.currentStep.name === 'basicInfo')
						$scope.currentStep = _.find($scope.steps, { name: 'startPage' });
					else
						$scope.currentStep = $scope.steps[i - 1];
					return;
				}
			}
		};

		$scope.setCreationMethod = function (choice) {
			if (choice === "blank")
				$scope.model.GuidProjectTemplate = "";

			$scope.creationMethod = choice;
			$scope.handleNextStep();
		};

		$scope.create = function () {
			if (!$scope.hasCreateAccess('Project')) return;
			if ($scope.isSaving) return;
			$scope.isSaving = true;

			if ($.trim($scope.model.Id) === '')
				$scope.model.Id = null;

			repository.createSingleDictionary(repository.apiData.project.url, $scope.model).then(function (response) {
				$scope.isSaving = false;
				$scope.model.Guid = response.Guid;
				$scope.model.Id = response.Id;
				repository.growl(translationService.translate('web-project-created', 'Opprettet prosjekt'), 'success');
				$scope.goBack('project.edit', { guid: $scope.model.Guid, menuGuid: $scope.navigation.params.menuGuid });
			}, function (error) {
				$scope.isSaving = false;
				swal(translationService.translate('web-project-error', 'Feil ved opprettelse'), error, 'error');
			});
		};

		$scope.onProjectTemplateSelect = function(project) {
			if (!project) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}

			if (project.Guid === $scope.model.GuidProjectTemplate) {
				$scope.model.GuidProjectTemplate = "";
				return;
			}

			$scope.model.GuidProjectTemplate = project.Guid;
		};

		$scope.onEstateSelect = function (estate) {
			if (!estate) {
				$scope.model.GuidEstate = "";
				return;
			}

			if ($scope.model.GuidEstate !== estate.Guid) {
				$scope.model.GuidBuilding = "";
				$scope.model.Building = "";
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidEstate = estate.Guid;
		};

		$scope.onBuildingSelect = function (building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidArea = "";
				$scope.model.Area = "";
			}

			$scope.model.GuidBuilding = building.Guid;

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== building.GuidEstate) {
				$scope.model.GuidEstate = building.GuidEstate;
				$scope.model.Estate = { Caption: building.Estate.Id + " - " + building.Estate.Description };
			}

			if (building.CostCenter) {
				$scope.model.CostCenter = { Caption: building.CostCenter.Caption };
				$scope.model.GuidCostCenter = building.GuidCostCenter;
			}
		};

		$scope.onAreaSelect = function (area) {
			if (!area) {
				$scope.model.GuidArea = "";
				return;
			}
			$scope.model.GuidArea = area.Guid;

			if (!$scope.model.GuidBuilding || $scope.model.GuidBuilding === "" || $scope.model.GuidBuilding !== area.GuidBuilding) {
				$scope.model.GuidBuilding = area.GuidBuilding;
				$scope.model.Building = { Caption: area.Building.Caption };
			}

			if (!$scope.model.GuidEstate || $scope.model.GuidEstate === "" || $scope.model.GuidEstate !== area.Building.GuidEstate) {
				$scope.model.GuidEstate = area.Building.Estate.Guid;
				$scope.model.Estate = { Caption: area.Building.Estate.Caption };
			}
		};

		$scope.onProjectCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidProjectCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidProjectCategory = category.Guid;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "estate":
					return filter;
				case "building":
					if ($scope.model.GuidEstate) {
						var estateFieldRule = _.filter($scope.fieldRules, function (o) { return o.FieldName === "GuidEstate"; });
						if (estateFieldRule.length === 1 && estateFieldRule[0].Hidden)
							return filter;
						filter.PropertyFilter = [{ Property: 'GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "area":
					filter = { GuidBuilding: $scope.model.GuidBuilding };
					if ($scope.model.GuidEstate) {
						filter.PropertyFilter = [{ Property: 'Building.GuidEstate', Operator: '=', Value: $scope.model.GuidEstate }];
					}
					return filter;
				case "person":
					return filter;
				case "supplier":
					filter.PropertyFilter = [{ Property: 'IsDeactivated', Operator: '=', Value: false }];
					return filter;
				case "project":
					filter.PropertyFilter = [{ Property: 'IsTemplate', Operator: '=', Value: true }];
					return filter;
			}
			return filter;
		};

		$scope.getPropertyValue = function (entity, column) {
			return ListService.GetPropertyValue(entity, column);
		};

		$scope.projectTemplateColumns = [
			{ Position: 1, Title: translationService.translate('web-project-id', 'Id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-project-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' }
		];

		// Project template table
		$scope.projectTemplates = new ngTableParams({
			page: 1,
			count: 10,
			sorting: { Id: 'asc' },
			filter: { IsTemplate: true }
		}, {
			total: 0,
			counts: [10, 25, 50],
			paginationMaxBlocks: 5,
			filterDelay: 10,
			getData: function ($defer, params) {
				if (!$scope.hasReadAccess('Project')) return;
				var columns = [];
				$scope.projectTemplateColumns.forEach(function (col) {
					columns.push(col.Property);
				});

				repository.GetPaginated(repository.apiData.project.url, params.page() - 1, params.count(), params.sorting(), params.filter(), null, JSON.stringify(columns)).then(function (result) {
					params.total(result.TotalCount);
					params.settings().filterDelay = 500;
					$defer.resolve(result.List);
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}
		});
	}
})();
