(function () {
	var app = angular.module('Plania');
	app.directive('controlListAnswersDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$rootScope', 'ListService', '$timeout', '$modal', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListAnswers.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $rootScope, listService, $timeout, $modal) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.controlListItemAnswersSearch = {
			focus: false,
			toggle: false
		};

		$scope.hasPageLoaded = $scope.currentTab === 'answers';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'answers' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		var filterTypes = [
			{ value: 'current', name: translationService.translate('web-controlList-answersTab-controlListItemAnswers-current', 'Gjeldende') },
			{ value: 'all', name: translationService.translate('web-controlList-answersTab-controlListItemAnswers-all', 'Alle') }
		];

		$scope.currentSelectedFilterType = filterTypes[0];

		$scope.setFilter = function (filterTypeValue) {
			var selectedFilterType = _.find(filterTypes, { value: filterTypeValue });
			if (selectedFilterType && filterTypeValue !== $scope.currentSelectedFilterType.value) {
				$scope.currentSelectedFilterType = selectedFilterType;
				$scope.controlListItemAnswers.reload();
			}
		};
		
		var sorting = {
			'ControlListItem.Name': 'asc'
		};

		$scope.controlListItemAnswersColumns = [
			{ Position: 1, Title: translationService.translate('web-controlList-answerTab-workOrder-caption', 'Arbeidsordre'), Property: 'ControlListXEntity.WorkOrder.Id', PropertyType: 'string', isCaption: true },
			{ Position: 2, Title: translationService.translate('web-controlList-answerTab-relatedEntity-caption', 'Tilknyttet objekt'), PropertyType: "string", isComplex: true, SortingDisabled: true },
			{ Position: 3, Title: translationService.translate('web-controlList-answerTab-controlListItem-name', 'Kontrollpunkt'), Property: 'ControlListItem.Name', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-controlList-answerTab-controlListItemAnswer-value', 'Svar'), Property: 'Value', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-controlList-answerTab-controlList-version', 'Versjon'), Property: 'ControlListXEntity.ControlList.Version', PropertyType: 'string' },
		];

		if ($scope.isUpdate) {
			$scope.controlListItemAnswers = new ngTableParams({
				page: 1,
				count: 10,
				sorting: sorting
			}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = [];
					var filter = {};

					$scope.controlListItemAnswersColumns.forEach(function (col) {
						if (col.Property)
							columns.push(col.Property);
					});

					columns.push("ControlListXEntity.WorkOrder.Description");
					columns.push("ControlListXEntity.GuidWorkOrder");
					columns.push("ControlListXEntity.GuidArea");
					columns.push("ControlListXEntity.Area.Id");
					columns.push("ControlListXEntity.Area.Description");
					columns.push("ControlListXEntity.Area.IsHousing");
					columns.push("ControlListXEntity.GuidEquipment");
					columns.push("ControlListXEntity.Equipment.Id");
					columns.push("ControlListXEntity.Equipment.Description");
					columns.push("ControlListXEntity.GuidPeriodicTask");
					columns.push("ControlListXEntity.PeriodicTask.Id");
					columns.push("ControlListXEntity.PeriodicTask.Description");

					switch ($scope.currentSelectedFilterType.value) {
						case 'current':
							filter.PropertyFilter = [{ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid }];
							break;
						case 'all':
							if ($scope.model.GuidMasterRecord)
								filter.PropertyFilter = [{ Property: 'ControlListXEntity.ControlList.GuidMasterRecord', Operator: '=', Value: $scope.model.GuidMasterRecord }];
							else
								filter.PropertyFilter = [{ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid }];
							break;
						default:
							filter.PropertyFilter = [{ Property: 'ControlListXEntity.GuidControlList', Operator: '=', Value: $scope.model.Guid }];
					}

					repository.GetPaginated(repository.apiData.controlListItemAnswer.url, params.page() - 1, params.count(), params.sorting(), filter, "", JSON.stringify(columns)).then(function (result) {
						$scope.controlListItemAnswers.settings().total = result.TotalCount;
						$scope.controlListItemAnswers.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		}

		$scope.openControlList = function (item) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: item.WorkOrder.Caption,
							controlList: $scope.model,
							controlListXEntity: item,
							restrictEdit: true
						};
					}
				}
			});
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.controlListItemAnswersContextMenuOptions = function (item) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-controlList-answersTab-controlListXEntityContextMenuOptions-openControlList', 'Åpne kontrolliste') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.openControlList(item.ControlListXEntity);
				}
			});

			if ($scope.hasReadAccess("WorkOrder") && item.ControlListXEntity.GuidWorkOrder) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.ControlListXEntity.GuidWorkOrder }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-workOrder-view', 'Åpne arbeidsordre')));
			}

			if ($scope.hasReadAccess("PeriodicTask") && item.ControlListXEntity.GuidPeriodicTask) {
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.ControlListXEntity.GuidPeriodicTask }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-periodicTask-view', 'Åpne periodisk rutine')));
			}

			if ($scope.hasReadAccess("Area") && item.ControlListXEntity.GuidArea) {
				if (item.ControlListXEntity.Area.IsHousing)
					options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.ControlListXEntity.GuidArea }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-housing-view', 'Åpne bolig')));
				else
					options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.ControlListXEntity.GuidArea }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-area-view', 'Åpne rom')));
			}

			if ($scope.hasReadAccess("Equipment") && item.ControlListXEntity.GuidEquipment) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.ControlListXEntity.GuidEquipment }), translationService.translate('web-controlList-answersTab-controlListItemAnswersContextMenuOptions-equipment-view', 'Åpne anlegg')));
			}

			return options;
		};


		var getLowestEntityCaption = function (entity) {
			if (entity.ControlListXEntity.Equipment) return entity.ControlListXEntity.Equipment.Caption;
			if (entity.ControlListXEntity.Area) return entity.ControlListXEntity.Area.Caption;

			if (entity.ControlListXEntity.PeriodicTask) {
				return translationService.translate('web-controlListXEntity-directConnectionPeriodicTask', 'Direkte tilkoblet rutine');
			} else {
				return translationService.translate('web-controlListXEntity-directConnectionWorkOrder', 'Direkte tilkoblet arbeidsordre');
			}
		};

		var getEntityIcon = function (entity) {
			if (entity.ControlListXEntity.Equipment) return 'zmdi-settings c-bluegray';
			if (entity.ControlListXEntity.Area) return 'zmdi-layers c-lightblue';
		};

		$scope.getPropertyValue = function (item, column) {
			if (column.isComplex) {
				var icon = '<i class="zmdi zmdi-hc-lg ' + getEntityIcon(item) + '"></i> ';
				var span = '<span>' + getLowestEntityCaption(item) + '</span>';
				return '<span>' + icon + span + '</span>';
			}

			if (column.isCaption) {
				var newColumn = {
					Position: column.Position,
					Property: column.Property.replace(".Id", ".Caption"),
					PropertyType: column.PropertyType,
					Title: column.Title
				};
				return listService.GetPropertyValue(item, newColumn);
			}

			return listService.GetPropertyValue(item, column);
		};
	}
})();
