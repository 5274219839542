(function () {
	angular.module('Plania').factory('DeviationService', ['Repository', 'TranslationService', '$rootScope', deviationService]);

	function deviationService(repository, translationService, $rootScope) {
		var service = {};

		var getWoDefaultDescription = function (model) {
			var deviationDesc = model.Id;
			if (model.DeviationType && model.DeviationType.Id)
				deviationDesc = model.Id + ' - ' + model.DeviationType.Id;

			return translationService.translate('deviation-new-workOrder-description', 'Utbedring av') + ' ' + deviationDesc;
		};

		service.createWorkOrder = function(deviation) {
			if (deviation.GuidCorrectiveWorkOrder) {
				swal('Feilmelding', 'Du kan ikke generere arbeidsordre på et avvik som allerede har en arbeidsordre generert.', 'error');
				return;
			}

			var estate = deviation.GuidEstate && deviation.Estate ? deviation.Estate.Caption : '';
			var building = deviation.GuidBuilding && deviation.Building ? deviation.Building.Caption : '';
			var equipment = deviation.GuidEquipment && deviation.Equipment ? deviation.Equipment.Caption : '';

			repository.persistedData.setPersistedData('workOrder.create', {
				guidEstate: deviation.GuidEstate,
				estate: estate,
				guidBuilding: deviation.GuidBuilding,
				building: building,
				guidEquipment: deviation.GuidEquipment,
				equipment: equipment,
				guidSelections: [deviation.Guid],
				fromDeviation: true,
				fromCondition: false,
				defaultDescription: getWoDefaultDescription(deviation),
				defaultText: deviation.ActionComment
			});
			$rootScope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		return service;
	}
})();
