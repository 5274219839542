(function () {
	angular.module('Plania').factory('TranslationService', ['Repository', '$localStorage', '$timeout', '$q', translationService]);

	function translationService(repository, $localStorage, $timeout, $q) {
		var service = {};
		var languageData = { isLoaded: false };
		var helpTextDict = { isLoaded: false };
		var numberOfTimesFetchedTranslations = 0;
		var lastNumberOfFetchedTranslations = 0;

		var setLanguageEntry = function (key, value) {
			languageData[key] = value;
			$localStorage.languageData = languageData;
		};

		var setHelpTextEntry = function(key, value) {
			helpTextDict[key] = value;
			$localStorage.helpTextDict = helpTextDict;
		};

		var defaultSystemNames = [
			{ systemname: 'web-building-choose', defaultValue: 'Velg bygg' },
			{ systemname: 'web-dataowner', defaultValue: 'Klient' },
			{ systemname: 'web-buildingselection-choose', defaultValue: 'Velg Utvalg' }
		];

		var alreadyGettingTranslations = false;

		var createDefaultTranslations = function () {
			defaultSystemNames.forEach(function (translation) {
				service.translate(translation.systemname, translation.defaultValue);
			});
		};

		service.getTranslations = function () {

			if (alreadyGettingTranslations) return;

			alreadyGettingTranslations = true;

			repository.getWithUrlParameter(repository.apiData.translate.url, "locale=" + $localStorage.locale)
				.then(function (result) {
					if (!Array.isArray(result)) {
						alreadyGettingTranslations = false;
						return;
					}

					delete $localStorage.languageData;
					delete $localStorage.helpTextDict;

					languageData = { isLoaded: false };
					helpTextDict = { isLoaded: false };
					result.forEach(function (row) {
						setLanguageEntry(row.Locale + row.SystemName, row.TranslatedValue);
						if (row.HasHelpText) {
							setHelpTextEntry(row.Locale + row.SystemName, row.HasHelpText);
						}
					});
					languageData.isLoaded = true;
                    createDefaultTranslations();

                    if (result.length !== lastNumberOfFetchedTranslations) {
						numberOfTimesFetchedTranslations = 0;
					} else {
						numberOfTimesFetchedTranslations++;
                    }
                    lastNumberOfFetchedTranslations = result.length;

					$timeout(function() {
							alreadyGettingTranslations = false;
					}, 10000);

				});
		};

		service.getEnumTranslation = function (enumName, enumProperty) {
			return service.translate('web-enum-' + enumName + '-' + enumProperty, enumProperty);
		};

		service.getLocale = function () { return repository.getLocale(); };

		service.setLocale = function (newLocale) {
			repository.setLocale(newLocale);
			service.getTranslations();
		};

		service.translate = function (systemName, defaultValue, model) {
            systemName = systemName.toLowerCase();
			if (!languageData.isLoaded) {
				var storageLanguageData = angular.fromJson($localStorage.languageData);

				if (storageLanguageData) {
					languageData = storageLanguageData;
				}
				service.getTranslations();
				languageData.isLoaded = true;
			}

			var value = languageData[$localStorage.locale + systemName];

			if (!value && defaultValue) {
				value = defaultValue;
				if (numberOfTimesFetchedTranslations < 10)
					languageData.isLoaded = false;
			} else if (!value && !defaultValue) {
				return systemName;
			}

			var matches = value.match(/%[a-zA-Z0-9]*%/g);
			if (matches) {
				matches.forEach(function (match) {
					var property = match.replace(new RegExp('%', 'g'), "");
					if (model && model[property])
						value = value.replace(new RegExp(match, 'g'), model[property]);
				});
			}

			return value;
		};

		service.getTranslationIfExists = function(systemName) {
			if (!systemName) return null;

			var translation = service.translate(systemName, '');
			if (translation && translation.toLowerCase() !== systemName.toLowerCase()) {
				return translation;
			}
			return null;
		};

		service.deleteLanguageEntry = function (key) {
			delete $localStorage.languageData[key];
        };

        service.setLanguageEntry = function (key, value) {
            setLanguageEntry(key, value);
		};

		service.getHelpText = function (key) {
			var deferred = $q.defer();
			repository.getWithUrlParameter(repository.apiData.translate.url, "systemName=" + key + "&locale=" + $localStorage.locale ).then(function(result) {
				deferred.resolve(result);
			});
			return deferred.promise;
		};

		service.hasHelpText = function (key) {
			key = key.toLowerCase();
			if (!helpTextDict.isLoaded) {
				var storageHelpTextDict = angular.fromJson($localStorage.helpTextDict);

				if (storageHelpTextDict) {
					helpTextDict = storageHelpTextDict;
				}
				service.getTranslations();
				helpTextDict.isLoaded = true;
			}
			return helpTextDict[$localStorage.locale + key];
        };

		return service;
	}
})();
