(function () {
	angular.module('Plania').controller('CleaningRequestController', ['$scope', 'Repository', 'TranslationService', '$timeout', controller]);

	function controller($scope, repository, translationService, $timeout) {
		$scope.request = {};
		$scope.autoCompleteModel = {};

		var cleaningColumns = ['Id', 'EstimatedTime', 'EstimatedCost', 'ExplanatoryText', 'NumericFieldName', 'CleaningType', 'ExitCleaningDecisionDate', 'ExitCleaningConfirmedDate'];

		var filter = {
			PropertyFilter: [{ Property: 'IsTemplate', Operator: '=', Value: '1' }]
		};

		if ($scope.extraData && $scope.extraData.exitGuidAreaXCleaningTask)
			filter.PropertyFilter.push({ Property: 'CleaningType', Operator: '<>', Value: 'ExitCleaning' });

		repository.GetPaginated(repository.apiData.cleaningTask.url, 0, 50, { 'Id': 'asc' }, filter, null, JSON.stringify(cleaningColumns)).then(function (result) {
			$scope.tasks = result.List;

			$scope.tasks.forEach(function (task) {
				if (task.CleaningType === 'ExitCleaning') {
					task.ExitCleaningRequestStatus = 'Possible';
					if (!task.ExitCleaningDecisionDate)
						task.ExitCleaningDecisionDate = getCurrentIsoDate('default');
					else
						task.ExitCleaningDecisionDate = getCurrentIsoDate(task.ExitCleaningDecisionDate);
					if (!task.ExitCleaningConfirmedDate)
						task.ExitCleaningConfirmedDate = getCurrentIsoDate('default');
					else
						task.ExitCleaningConfirmedDate = getCurrentIsoDate(task.ExitCleaningConfirmedDate);
				}

				task.AttributeValues.forEach(function (attribute) {
					attribute.TextValue = attribute.NamedSelectionDefaultOption;
				});
			});
		});

		$scope.selectTask = function (task) {
			$scope.selectedTask = task;
		};

		$scope.isTimeInPast = function (fieldName) {
			if (!$scope.selectedTask) return false;
			var time = $scope.selectedTask[fieldName];
			if (!time) return false;

			var currentTime = new Date().getTime();
			var selectedTime = new Date(time).getTime();
			if (currentTime > selectedTime)
				return true;
			return false;
		};

		var getCurrentIsoDate = function (input) {
			if (!input) return null;
			var date = new Date();
			var selectedDate = new Date(input);

			if (input === 'default')
				date.setHours(12, 0, 0);
			else
				date.setHours(selectedDate.getHours(), selectedDate.getMinutes());
			return date.toISOString();
		};

		$scope.send = function () {
			$scope.areaCleaningTask = {
				GuidArea: $scope.model.Guid,
				GuidCleaningTask: $scope.selectedTask.Guid,
				AttributeValues: $scope.selectedTask.AttributeValues
			};

			if ($scope.selectedTask.CleaningType === 'ExitCleaning') {
				if ($scope.selectedTask.ExitCleaningRequestStatus === 'Possible') {
					$scope.areaCleaningTask.ExitCleaningConfirmedDate = null;
					$scope.areaCleaningTask.ExitCleaningDecisionDate = getCurrentIsoDate($scope.selectedTask.ExitCleaningDecisionDate);
				}
				else if ($scope.selectedTask.ExitCleaningRequestStatus === 'Discharged') {
					$scope.areaCleaningTask.ExitCleaningDecisionDate = null;
					$scope.areaCleaningTask.ExitCleaningConfirmedDate = new Date().toISOString();
				}
				else {
					$scope.areaCleaningTask.ExitCleaningDecisionDate = null;
					$scope.areaCleaningTask.ExitCleaningConfirmedDate = getCurrentIsoDate($scope.selectedTask.ExitCleaningConfirmedDate);
				}
				
				$scope.areaCleaningTask.ExitCleaningRequestStatus = $scope.selectedTask.ExitCleaningRequestStatus;
			}

			$scope.areaCleaningTask.AttributeValues.forEach(function (attribute) {
				if (attribute.ValueType === 'Date' && attribute.DateValue)
					attribute.DateValue = attribute.DateValue.toISOString();
			});

			repository.createSingleDictionary(repository.apiData.areaXCleaningTask.url, $scope.areaCleaningTask).then(function (result) {
				repository.growl('Innmeldingen ble registrert', 'success');
				$scope.selectedTask = {};
				$scope.areaCleaningTask = {};

				$scope.ok(result);
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};
	}
})();
