(function () {

	var app = angular.module('Plania');
	app.directive('recurrenceRule', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=model',
				rruleText: '=rruleText', // Binding to allow parent controller/directive to access the rule text.
				hideRuleText: '=hideRuleText', // Ability to hide the ruletext if the parent controller/directive has any special rules
				isPeriod: '=isPeriod',
				viewMode: '=viewMode',
				useModelDates: '=useModelDates', // Show Start and UntilDates that will update StartDate and UntilDate on the model
				useRecurrenceDates: '=useRecurrenceDates', // Show Start and Until that will update StartDate and Until on the recurrenceRule
			},
			controller: ['$scope', 'Constants', '$filter', controller],
			templateUrl: 'app/common/directives/views/recurrenceRuleDirective.html'
		};
	});

	function controller($scope, constants, $filter) {
		$scope.viewMode = $scope.viewMode === undefined ? false : $scope.viewMode;

		$scope.repeatPeriod = $scope.model && $scope.model.RecurrenceRule && $scope.model.RecurrenceRule.Unit !== 'Unit';
		$scope.months = constants.months;

		$scope.changeRepeat = function () {
			if ($scope.repeatPeriod) {
				$scope.model.RecurrenceRule = {
					StartDate: $scope.model.RecurrenceRule.StartDate,
					Unit: 'Week',
					PeriodNumber: $scope.model.RecurrenceRule.PeriodNumber,
					PeriodUnit: $scope.model.RecurrenceRule.PeriodUnit,
				};

				$scope.onUnitSelect();
				$scope.setWeekDay('Monday');
			}
			else {
				$scope.model.RecurrenceRule = {
					StartDate: $scope.model.RecurrenceRule.StartDate,
					Unit: 'Once',
					PeriodNumber: $scope.model.RecurrenceRule.PeriodNumber,
					PeriodUnit: $scope.model.RecurrenceRule.PeriodUnit
				};
				$scope.onUnitSelect();
			}
		};


		function convertWeekdayArray(array) {
			var i = 0;
			if (typeof (array[0]) === 'string') {
				for (i; i < array.length; i++) {
					array[i] = constants.weekdaysToNumber[array[i]];
				}
			} else if (typeof (array[0]) === 'number') {
				for (i; i < array.length; i++) {
					array[i] = constants.numberToWeekdays[array[i]];
				}
			}
		}

		function sortNumberArray(a, b) {
			return a - b;
		}

		$scope.toggleWeekDaySelection = function (weekday) {
			var array = $scope.model.RecurrenceRule.ByDay;
			var idx = array.indexOf(weekday);

			if (idx > -1) {
				array.splice(idx, 1);
			} else {
				array.push(weekday);
				convertWeekdayArray(array);
				array.sort(sortNumberArray);
				convertWeekdayArray(array);
			}

			$scope.model.RecurrenceRule.ByDay = array;
		};

		$scope.setWeekDay = function (weekday) {
			var array = [weekday];
			convertWeekdayArray(array);
			array.sort(sortNumberArray);
			convertWeekdayArray(array);

			$scope.model.RecurrenceRule.ByDay = array;
		};

		$scope.onDailySelect = function (value) {
			if (value === 1) {
				$scope.model.RecurrenceRule.Interval = 1;
				$scope.model.RecurrenceRule.ByDay = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
			} else {
				$scope.model.RecurrenceRule.ByDay = [];
			}
		};

		$scope.onUnitSelect = function () {
			if ($scope.model.RecurrenceRule.Unit === 'Day') {
				$scope.onDailySelect($scope.selectedDaily);
			}
			$scope.model.RecurrenceRule.ByMonthDay = [];
			$scope.model.RecurrenceRule.ByMonth = [];
			$scope.model.RecurrenceRule.ByDay = [];

			if ($scope.model.RecurrenceRule.Unit === 'Week' || $scope.model.RecurrenceRule.Unit === 'Month' || $scope.model.RecurrenceRule.Unit === 'Year')
				$scope.model.RecurrenceRule.Interval = 1;

			if ($scope.isPeriod && $scope.model.RecurrenceRule.Unit === 'Week')
				$scope.setWeekDay('Monday');

			if ($scope.model.RecurrenceRule.Unit === 'Month')
				$scope.model.RecurrenceRule.ByMonthDay = [1];

			if ($scope.model.RecurrenceRule.Unit === 'Year') {
				$scope.model.RecurrenceRule.ByMonthDay = [1];
				$scope.model.RecurrenceRule.ByMonth = [1];
			}
		};

		var setEndDateFromPeriod = function () {
			if ($scope.repeatPeriod) return;
			if (!$scope.model.RecurrenceRule.PeriodUnit || !$scope.model.RecurrenceRule.PeriodNumber || $scope.model.RecurrenceRule.StartDate === undefined)
				return;

			var date = new Date($scope.model.RecurrenceRule.StartDate);

			if ($scope.model.RecurrenceRule.PeriodUnit === 'Day')
				date.setDate(date.getDate() + $scope.model.RecurrenceRule.PeriodNumber);
			else if ($scope.model.RecurrenceRule.PeriodUnit === 'Week')
				date.setDate(date.getDate() + $scope.model.RecurrenceRule.PeriodNumber * 7);
			else if ($scope.model.RecurrenceRule.PeriodUnit === 'Month')
				date = addMonths(date, $scope.model.RecurrenceRule.PeriodNumber);

			$scope.model.RecurrenceRule.EndDate = date;
		};

		function addMonths(date, months) {
			var d = date.getDate();
			date.setMonth(date.getMonth() + months);
			if (date.getDate() !== d) {
				date.setDate(0);
			}
			return date;
		}

		$scope.setValueLimits = function () {
			if ($scope.model.RecurrenceRule.ByMonthDay) {
				if ($scope.model.RecurrenceRule.ByMonthDay[0] < 1)
					$scope.model.RecurrenceRule.ByMonthDay[0] = 1;
				else if ($scope.model.RecurrenceRule.ByMonthDay[0] > 31)
					$scope.model.RecurrenceRule.ByMonthDay[0] = 31;
			}

			if ($scope.model.RecurrenceRule.Interval !== undefined && $scope.model.RecurrenceRule.Interval < 1)
				$scope.model.RecurrenceRule.Interval = 1;
		};

		if ($scope.model.RecurrenceRule)
			$scope.rruleText = $filter('plRecurrenceRuleFilter')($scope.model.RecurrenceRule);

		if ($scope.useModelDates) {
			// Synchronize just to simplify recurrenceRule text
			$scope.$watch('model.StartDate', function (newVal) {
				if ($scope.model && $scope.model.RecurrenceRule)
					$scope.model.RecurrenceRule.StartDate = newVal;
			});

			$scope.$watch('model.UntilDate', function (newVal) {
				if ($scope.model && $scope.model.RecurrenceRule)
					$scope.model.RecurrenceRule.Until = newVal;
			});
		}

		$scope.$watch('model.RecurrenceRule', function (newValue, oldValue) {
			if (!newValue) return;
			$scope.setValueLimits();
			$scope.rruleText = $filter('plRecurrenceRuleFilter')(newValue);
			$scope.selectedDaily = newValue.Unit === 'Day' && newValue.Interval === 1 &&
				JSON.stringify(newValue.ByDay) === JSON.stringify(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);

			$scope.repeatPeriod = newValue.Unit !== 'Once';
			if ($scope.isPeriod)
				setEndDateFromPeriod();
		}, true);
	}
})();
