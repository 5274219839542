(function () {
	angular.module('Plania').controller('MaintenancePlanController', ['$scope', 'Repository', 'TranslationService', '$filter', controller]);

	function controller($scope, repository, translationService, $filter) {
		// Variable to check if we have initialized the first time.
		// Used to block call on first load (since user should select filters first)
		$scope.hasInitialized = false;
		$scope.showFilters = true;

		$scope.tasks = [];
		$scope.columnData = [];
		$scope.totalCount = 0;

		$scope.sortingProperty = 'StartDate';
		$scope.sortingDirection = 'desc';
		$scope.sorting = {};
		$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;

		$scope.viewMode = 'days';
		
		//Contain the filterValues
		$scope.filterValues = {};

		// Contain the captions from each autocomplete field
		$scope.autocompleteModel = {};
		$scope.filters = { ActivityStatus: 'Active', PropertyFilter: [] };
		$scope.loading = false;

		$scope.count = 100;
		$scope.totalCount = 0;

		$scope.sortingProperties = [
			{ value: 'Description', label: 'Beskrivelse', type: 'string' },
			{ value: 'StartDate', label: 'Startdato', type: 'date' },
			{ value: 'EstimatedCost', label: 'Estimert kostnad', type: 'string' },
			{ value: 'EstimatedTime', label: 'Estimert tid', type: 'string' },
			{ value: 'Building.Id', label: 'Bygning ID', typee: 'string' },
			{ value: 'Building.Description', label: 'Bygningsbeskrivelse', type: 'string' },
			{ value: 'Equipment.Id', label: 'Anlegg ID', type: 'string' },
			{ value: 'Equipment.Description', label: 'Anleggsbeskrivelse', type: 'string' },
			{ value: 'Area.Id', label: 'Areal ID', type: 'string' },
			{ value: 'ResourceGroup.Id', label: 'Ressurs' },
			{ value: 'Type', label: 'Arbeidsordretype' },
			{ value: 'ActivityCategory.Description', label: 'Kategori' },
			{ value: 'Supplier.Description', label: 'Leverandør' },
			{ value: 'Person.LastName', label: 'Ansvarlig person(Etternavn)' },
		];

		$scope.workOrderTypes = [
			{ title: 'KO', value: 0 },
			{ title: 'PR', value: 1 },
			{ title: 'FV', value: 2 },
			{ title: 'ME', value: 3 },
			{ title: 'Test', value: 4 },
			{ title: 'Kontroll', value: 5 },
			{ title: 'AV', value: 6 },
			{ title: 'Alarm', value: 7 },
			{ title: 'VL', value: 8 },
			{ title: 'TT', value: 9 },
		];

		$scope.dueDateIntervals = [
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextMonth', 'Neste måned'),
				value: "nextMonth"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextThreeMonths', 'Neste 3 måneder'),
				value: "nextThreeMonths"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextHalfYear', 'Neste halvår'),
				value: "nextHalfYear"
			},
			{
				title: translationService.translate('web-maintenancePlan-dueDateIntervals-nextYear', 'Neste år'),
				value: "nextYear"
			},
		];

		$scope.filters.dueDateInterval = "nextThreeMonths";

		var getDueDateFilters = function (filters) {
			switch (filters.dueDateInterval) {
				case "nextMonth":
					filters.PropertyFilter.push({
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(1, 'month').toISOString()
					});
					break;
				case "nextThreeMonths":
					filters.PropertyFilter.push({
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(3, 'month').toISOString()
					});
					break;
				case "nextHalfYear":
					filters.PropertyFilter.push({
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(6, 'month').toISOString()
					});
					break;
				case "nextYear":
					filters.PropertyFilter.push({
						Property: "DueDate", Operator: "<=", Value: moment().utc().startOf('day').add(1, 'year').toISOString()
					});
					break;
			}
			delete filters.dueDateInterval;
		};

		$scope.getData = function () {
			// User is required to "activate" thhe maintnanceplan
			if (!$scope.hasInitialized) return;

			if ($scope.viewMode === "months")
				$scope.filters.NumberOfYearsToCalculate = 3;
			
			var filters = angular.copy($scope.filters);
			getDueDateFilters(filters);

			filters.Interval = 1;
			switch ($scope.viewMode) {
				case 'months':
					filters.Interval = 3;
					break;
				case 'weeks':
					filters.Interval = 2;
					break;
				case 'days':
					filters.Interval = 1;
					break;
			}

			$scope.loading = true;

			repository.GetPaginated(repository.apiData.maintenance.url, 0, $scope.count, $scope.sorting, filters, null).then(function (result) {
				var data = [];
				result.List.forEach(function (item) {
					var selectedSorting = _.find($scope.sortingProperties, function (o) { return o.value === $scope.sortingProperty; });
					$scope.loading = false;
					var object = {
						name: item.Title,
						filterValue: item.Subtitle,
						filterLabel: selectedSorting.label,
						//Object containing the values for the detailed view of the row
						detailProperties: [],
						dataObj: item,
						values: []
					};

					if (item.Building) object.detailProperties.push({ name: 'Bygg', value: item.Building });
					if (item.Equipment) object.detailProperties.push({ name: 'Anlegg', value: item.Equipment });
					if (item.Area) object.detailProperties.push({ name: 'Areal', value: item.Area });
					if (item.ResourceGroup) object.detailProperties.push({ name: 'Ressurs', value: item.ResourceGroup });
					if (item.WorkOrderType) object.detailProperties.push({ name: 'Type', value: item.WorkOrderType.Description });
					if (item.ActivityCategory) object.detailProperties.push({ name: 'Kategori', value: item.ActivityCategory });
					if (item.Supplier) object.detailProperties.push({ name: 'Leverandør', value: item.Supplier });
					if (item.StartDate) object.detailProperties.push({ name: 'Start dato', value: $filter('date')(item.StartDate, 'dd.MM.yyyy') });
					if (item.EstimatedCost) object.detailProperties.push({ name: 'Est. kost', value: item.EstimatedCost });
					if (item.EstimatedTime) object.detailProperties.push({ name: 'Est. tid', value: item.EstimatedTime });

					item.MaintenanceValues.forEach(function (value) {
						var className = value.IsCompleted ? "ganttGreen" : value.IsPastDueDate ? "ganttRed" : "";
						if (value.GuidPeriodicTask) className = "ganttGray transparent-bar";
						object.values.push({
							from: "/Date(" + new Date(value.StartDate).getTime() + ")/",
							to: "/Date(" + new Date(value.EndDate).getTime() + ")/",
							//label: item.Title,
							customClass: className,
							dataObj: value
						});
					});


					data.push(object);
				});

				$scope.tasks = data;
				$scope.columnData = result.ColumnData;
				$scope.totalCount = result.TotalCount;
			}, function (error) {
				$scope.loading = false;
				repository.growl(error, 'danger');
			});
		};

		$scope.getData();

		$scope.initialize = function () {
			if ($scope.hasInitialized) return;
			$scope.hasInitialized = true;
			$scope.getData();
		};

		$scope.addMoreData = function () {
			if ($scope.loading) return;
			$scope.count += 100;
			$scope.getData();
		};

		$scope.lessData = function () {
			if ($scope.loading) return;
			$scope.count -= 100;
			$scope.getData();
		};

		$scope.setSorting = function (item) {
			if ($scope.sortingProperty === item.value) {
				$scope.sortingDirection = $scope.sortingDirection === 'desc' ? 'asc' : 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			} else {
				$scope.sorting = {};
				$scope.sortingProperty = item.value;
				$scope.sortingDirection = 'desc';
				$scope.sorting[$scope.sortingProperty] = $scope.sortingDirection;
			}

			$scope.getData();
		};

		$scope.setViewMode = function (viewMode) {
			$scope.viewMode = viewMode;
			$scope.getData();
		};

		var findExistingFilter = function (key) {
			return _.findIndex($scope.filters.PropertyFilter, function (p) { return p.Property === key; });
		};

		$scope.$watch('filterValues', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			for (var key in newValue) {
				var indexOfFilter = findExistingFilter(key);
				var currentFilterValue = $scope.filterValues[key];

				if (indexOfFilter !== -1) {
					// Already has added filter, check if we need to update it (if it has an actual value) or remove it (if the value is "empty")
					if (currentFilterValue !== 0 && (!currentFilterValue || currentFilterValue === ""))
						$scope.filters.PropertyFilter.splice(indexOfFilter, 1);
					else
						$scope.filters.PropertyFilter[indexOfFilter].Value = currentFilterValue;
				} else {
					$scope.filters.PropertyFilter.push({ Property: key, Operator: "=", Value: currentFilterValue });
				}
			}

			$scope.getData();
		}, true);

		$scope.$watch('filters.showWorkordersWithoutStartDate', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			$scope.getData();
		});

		$scope.$watch('filters.dueDateInterval', function (newValue, oldValue) {
			if (newValue === oldValue) return;
			$scope.getData();
		});

		$scope.$on($scope.events.newSelection, function () {
			$scope.getData();
		});
	}
})();
