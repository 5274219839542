// Used to compress the url used for list views to reduce the chance of having URLs above ~2000 characters
// https://stackoverflow.com/questions/417142/what-is-the-maximum-length-of-a-url-in-different-browsers
(function () {
	angular.module('Plania').factory('ListCompressionService', [
		function () {
			var service = {};

			service.encodeListUri = function (stateObject) {
				var minified = minifyStateObject(stateObject);
				var encoded = rison.encode_uri(minified);
				return encoded;
			};

			service.decodeListUri = function (encoded) {
				var minified = rison.decode_uri(encoded);
				var stateObject = decodeUrlFilter(minified);
				return stateObject;
			};


			var columnsMapping = {
				"Position": "i",
				"Title": "t",
				"Property": "p",
				"PropertyType": "pt",
				"AutocompleteService": "as",
				"AutocompleteProperty": "ap",
				"Filter": "f",
				"SortingDisabled": "sd"
			};

			var listSetupMapping = {
				"Columns": "c",
				"IsDefaultView": "d",
				"Guid": "g",
				"Id": "i",
				"inMemory": "m",
				"EntityName": "n",
				"IsPublicView": "p",
				"Sorting": "s",
                "EntityType": "t",
                "GroupBy": "b"
			};

			var propertyFilterMapping = {
				"Operand": "a",
				"Operator": "o",
				"Property": "p",
				"Value": "v"
			};

			var minifyStateObject = function (stateObject) {
				var minifiedStateObject = angular.copy(stateObject);

				var minifiedPropertyFilter = [], minifiedListSetup = {};

				//Minify columns
				if (minifiedStateObject.selectedListSetup) {
					if (minifiedStateObject.selectedListSetup.Columns) {
						var minifiedColumns = [];
						minifiedStateObject.selectedListSetup.Columns.forEach(function(column) {
							var newColumn = {};
							for (var property in column) {
								newColumn[columnsMapping[property]] = column[property];
							}

							minifiedColumns.push(newColumn);
						});

						minifiedStateObject.selectedListSetup.Columns = minifiedColumns;
					}

					//minify listSetup
					for (var property in minifiedStateObject.selectedListSetup) {
						minifiedListSetup[listSetupMapping[property]] = minifiedStateObject.selectedListSetup[property];
					}

					minifiedStateObject.selectedListSetup = minifiedListSetup;
				}
				//minify propertyFilter
				if (minifiedStateObject.filter.PropertyFilter && minifiedStateObject.filter.PropertyFilter.length > 0) {
					minifiedStateObject.filter.PropertyFilter.forEach(function (filter) {
						var newFilter = {};
						for (var property in filter) {
							newFilter[propertyFilterMapping[property]] = filter[property];
						}

						minifiedPropertyFilter.push(newFilter);
					});

					minifiedStateObject.filter.PropertyFilter = minifiedPropertyFilter;
				}
				return minifiedStateObject;
			};


			var decodeUrlFilter = function (filterString) {
				var decodedUrlFilter = angular.copy(filterString);

				var decodedColumns = [], decodedPropertyFilter = [];

				if (decodedUrlFilter.selectedListSetup) {
					//decode listSetup
					decodedListSetup = {};
					for (var property in decodedUrlFilter.selectedListSetup) {
						for (var mappingProperty in listSetupMapping) {
							if (listSetupMapping[mappingProperty] === property) {
								decodedListSetup[mappingProperty] = decodedUrlFilter.selectedListSetup[property];
							}
						}
					}
					decodedUrlFilter.selectedListSetup = decodedListSetup;

					if (decodedUrlFilter.selectedListSetup.Columns) {
						//decode columns
						decodedUrlFilter.selectedListSetup.Columns.forEach(function (column) {
							var newColumn = {};
							for (var property in column) {
								for (var mappingProperty in columnsMapping) {
									if (columnsMapping[mappingProperty] === property) {
										newColumn[mappingProperty] = column[property];
									}
								}
							}
							decodedColumns.push(newColumn);
						});

						decodedUrlFilter.selectedListSetup.Columns = decodedColumns;
					}
				}


				if (decodedUrlFilter.filter) {
					//decode filter
					if (decodedUrlFilter.filter.PropertyFilter && decodedUrlFilter.filter.PropertyFilter.length > 0) {
						decodedUrlFilter.filter.PropertyFilter.forEach(function(filter) {
							var newFilter = {};
							for (var property in filter) {
								for (var mappingProperty in propertyFilterMapping) {
									if (propertyFilterMapping[mappingProperty] === property) {
										newFilter[mappingProperty] = filter[property];
									}
								}
							}

							decodedPropertyFilter.push(newFilter);
						});

						decodedUrlFilter.filter.PropertyFilter = decodedPropertyFilter;
					}
				}

				return decodedUrlFilter;

			};


			return service;
		}]);
})();
