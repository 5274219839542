(function () {

	angular.module('Plania').controller('WebProfileController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'TranslationService', listController]);

	function listController($scope, repository, $stateParams, ngTableParams, $modal, translationService) {
		$scope.update = $scope.navigation.current.name === 'webProfile.edit';
		$scope.isMenuCollapsed = true;
		$scope.isUserCollapsed = true;
		$scope.model = {};
		$scope.menus = [];
		$scope.isWebMenusChanging = false;

		var updateAccess = function () {
			if ($scope.isUpdate) {
				var checkOtherDO = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !repository.authService.hasEditAccess(repository.commonService.prefix.WebProfile, checkOtherDO);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebProfile, checkOtherDO);
			} else {
				$scope.restrictEdit = !repository.authService.hasCreateAccess(repository.commonService.prefix.WebProfile);
				$scope.restrictDelete = !repository.authService.hasDeleteAccess(repository.commonService.prefix.WebProfile);
			}
		};

		updateAccess();

		var getMenus = function () {
			if (!$scope.hasReadAccess("WebProfileXWebMenu")) {
				$scope.menus = [];
				return;
			}

			var columns = [
				'IndexPosition',
				'WebMenu.Name', 'WebMenu.Description'
			];

			repository.GetPaginated(repository.apiData.webProfileWebMenu.url, 0, -1, { IndexPosition: 'asc' }, { PropertyFilter: [{ Property: "GuidWebProfile", Operator: "=", Value: $stateParams.guid }] }, null, JSON.stringify(columns)).then(function (menuResult) {
				if (menuResult) {
					$scope.menus = menuResult.List;
				}
			}, function (error) {
				repository.growl(error, 'danger');
			});
		};

		var getUserTable = function () {
			$scope.userTable = new ngTableParams({
				page: 1,
				count: 25,
				sorting: { 'User.RealName': 'asc' },
				filter: { PropertyFilter: [{ Property: 'GuidWebProfile', operator: '=', value: $stateParams.guid }, { Property: 'User.GuidUserGroup', operator: '=', value: "" }] }
			},
				{
					total: 0,
					filterDelay: 10,
					counts: [],
					getData: function ($defer, params) {
						if (!$scope.hasReadAccess("UserXWebProfile")) {
							$defer.resolve([]);
							return;
						}

						repository.GetPaginated(repository.apiData.userXWebProfile.url, params.page() - 1, params.count(), params.sorting(), params.filter()).then(function (result) {
							$scope.userTable.settings().total = result.TotalCount;
							$scope.userTable.settings().filterDelay = 500;
							$defer.resolve(result.List);
						}, function (error) {
								repository.growl(error, 'danger');
						});
					}
				});
		};


		if ($scope.update) {
			getMenus();
			repository.getSingle(repository.apiData.webProfile.url, $stateParams.guid).then(function (result) {
				$scope.model = result.Data;
				updateAccess();
			}, function (error) {
				repository.growl(error, 'danger');
			});
			getUserTable();
		}

		$scope.updateWebProfile = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ? 'Web profil har blitt lagret' : 'Web profil har blitt opprettet', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (result) {
				repository.growl(result, 'danger');
			};

			if ($scope.update) {
				if (hasDuplicateIndexPositions() && $scope.hasEditAccess("WebProfileXWebMenu"))
					reorderAndSave();

				repository.updateSingleDictionary(repository.apiData.webProfile.url, $scope.model).then(success).catch(error);
			}
			else
				repository.createSingleDictionary(repository.apiData.webProfile.url, $scope.model).then(success).catch(error);
		};

		$scope.webMenuTreeOptions = {
			dropped: function (event) {
				if (event.dest.nodesScope.$parent.nodropEnabled) return;
				var draggedDown = event.source.index > event.dest.index;
				var startIndex = draggedDown ? event.dest.index : event.source.index;
				var endIndex = draggedDown ? event.source.index : event.dest.index;
				if (startIndex === endIndex) return;
				setTimeout(function () {
					reorderAndSave(startIndex, endIndex);
				}, 0);
			},
		};

		var hasDuplicateIndexPositions = function () {
			var indexPositions = [];
			try {
				for (var i = 0; i < $scope.menus.length; i++) {
					var indexPosition = $scope.menus[i].IndexPosition;
					if (indexPositions.includes(indexPosition)) return true;
					indexPositions.push(indexPosition);
				}
				return false;
			} catch (error) {
				return false;
			}
		};

		var reorderAndSave = function (startIndex, endIndex) {
			if ($scope.menus.length === 0) return;

			if (startIndex === undefined || startIndex >= $scope.menus.length)
				startIndex = 0;

			if (endIndex === undefined || endIndex <= startIndex)
				endIndex = $scope.menus.length - 1;

			// Cleanup all if we have any duplicates
			if (hasDuplicateIndexPositions()) {
				startIndex = 0;
				endIndex = $scope.menus.length - 1;
			}

			if (endIndex <= startIndex) return;

			var changeList = [];
			for (var i = startIndex; i <= endIndex; i++) {
				var menu = $scope.menus[i];
				menu.IndexPosition = i;
				changeList.push(JSON.stringify({ Guid: menu.Guid, IndexPosition: menu.IndexPosition }));
			}

			$scope.isWebMenusChanging = true;
			repository.patch(repository.apiData.webProfileWebMenu.url, null, changeList).then(function (result) {
				$scope.isWebMenusChanging = false;
				repository.growl(translationService.translate('web-webProfile-reorderAndSave-success', 'Webmenyene har blitt oppdatert'), "success");
			}, function (error) {
				$scope.isWebMenusChanging = false;
			});
		};

		var getHighestIndexPosition = function () {
			if ($scope.menus.length === 0) return 0;

			var maxMenu = _.max($scope.menus, function (menu) {
				return menu.IndexPosition;
			});
			return maxMenu ? maxMenu.IndexPosition : 0;
		};

		$scope.addMenu = function () {
			if ($scope.isWebMenusChanging) return;

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til web meny',
							url: repository.apiData.webMenu.url,
							sorting: { 'Name': 'asc'},
							columns: [
								{ Title: translationService.translate('web-webMenu-name'), Property: 'Name', PropertyType: 'string' },
								{ Title: translationService.translate('web-webMenu-description'), Property: 'Description', PropertyType: 'string' }
							],
							useObjects: false
						};
					}
				}
			}).result.then(function (menuGuids) {
				var i = getHighestIndexPosition();
				var menus = _.map(menuGuids, function (menuGuid) {
					return JSON.stringify({ GuidWebMenu: menuGuid, GuidWebProfile: $scope.model.Guid, IndexPosition: i++ });
				});
				$scope.isWebMenusChanging = true;
				repository.createMultiple(repository.apiData.webProfileWebMenu.url, menus).then(function (result) {
					$scope.isWebMenusChanging = false;
					repository.growl(translationService.translate('web-webProfile-addMenu-success', 'Webprofiler har blitt lagt til'), 'success');
					getMenus();
				}, function (error) {
					$scope.isWebMenusChanging = false;
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeMenu = function (webProfileXWebMenu) {
			if ($scope.isWebMenusChanging) return;
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-webmenu-message', 'Menyen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-webmenu-button-confirm', 'Ja, fjern menyen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				$scope.isWebMenusChanging = true;
				repository.deleteSingle(repository.apiData.webProfileWebMenu.url, webProfileXWebMenu.Guid).then(function (result) {
					$scope.isWebMenusChanging = false;
					swal(translationService.translate('web-swal-webprofile-webmenu-success', 'Menyen ble fjernet!'), result, "success");
					_.remove($scope.menus, function (webProfile) {
						return webProfile.Guid === webProfileXWebMenu.Guid;
					});
					reorderAndSave();
					swal.close();
				}, function (error) {
					$scope.isWebMenusChanging = false;
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeUser = function (user) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-user-message', 'Brukeren vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-user-button-confirm', 'Ja, fjern brukeren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
					repository.deleteSingle(repository.apiData.userXWebProfile.url, user.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webprofile-user-success', 'Brukeren ble fjernet!'), result, "success");
						repository.growl('Brukeren har blitt fjernet', 'success');
						$scope.userTable.reload();
					});
			});
		};

		$scope.addUser = function () {
			var alreadySavedGuids = _.map($scope.userTable.data, 'GuidUser');

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				size: 'lg',
				resolve: {
					modalParams: function () {
						var propertyFilter = [
							{ Property: 'GuidUserGroup', Operator: '=', Value: '' },
							{ Property: 'IsUserGroup', Operator: '=', Value: 1, allowToggle: true, filterValue: 'Brukergruppe', isDisabled: true }];
						return {
							title: 'Velg bruker',
							columns: [
								{ Title: translationService.translate('web-user-username'), Property: 'Username' },
								{ Title: translationService.translate('web-user-realName'), Property: 'RealName' },
							],
							useObjects: false,
							url: repository.apiData.user.url,
							sorting: { Username: 'asc' },
							filter: { PropertyFilter: propertyFilter },
							alreadySavedGuids: alreadySavedGuids
						};
					}
				}
			}).result.then(function (selectedEntities) {
				var toSave = _.difference(selectedEntities, alreadySavedGuids);
				var userXWebProfiles = _.map(toSave, function (entity) {
					return JSON.stringify({ GuidUser: entity, GuidWebProfile: $scope.model.Guid, IndexPosition: 999 });
				});
				repository.createMultiple(repository.apiData.userXWebProfile.url, userXWebProfiles).then(function (result) {
					$scope.userTable.reload();
				}, function (error) {
					repository.growl(error, 'danger');
				});
			});
		};

		$scope.removeWebProfile = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-webprofile-message', 'MenyProfilen vil bli fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-webprofile-button-confirm', 'Ja, fjern Menyprofilen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.webProfile.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-webprofile-success', 'MenyProfilen ble fjernet!'), result, "success");
						repository.growl(translationService.translate('Web-profile-deleted-message', 'Web profilen har blitt slettet.'), 'success');
						$scope.goBack('webProfile.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
			getMenus();
			if ($scope.userTable && $scope.userTable.reload)
				$scope.userTable.reload();
		});
	}
})();
