/*
 * Plania Table Header
 * Directive for creating dynamic table headers
 *
 * Required parameters:
 *      -columns: A list of the columns that should be shown (should be the same list used within the table to bind values)
 *      -table: The ng-table variable
 *      -list: A reference to the array for selectedrows in the table. Used for mass selecting the rows in the list.
 * Optional:
 *      -selectable: true/false. Used to hide the mass-select checkbox in the header if not needed. default:false
 *      -hideContext: true/false. Used to hide the context-menu action in the header if not needed. default:false
 *      -showEmptyEndColumn: true/false. Used to show an empty column at the end. Usually used for fixed header and there exists an extra column in the rows.
 *      -showEmptyStartColumn: true/false. Used to show an empty column at the start. Usually used to show some decocation at the first column.
 */

(function () {
	var app = angular.module('Plania');

	app.directive('plTableHeader', function () {
		return {
			restrict: 'A',
			scope: {
				columns: '=columns',
				table: '=table',
				list: '=list',
				selectable: '=selectable',
				hideContext: '=hideContext',
				useObjects: '=useObjects',
				showEmptyEndColumn: '=showEmptyEndColumn',
				showEmptyStartColumn: '=showEmptyStartColumn'
			},
			controller: ['$scope', tableHeaderController],
			templateUrl: 'app/common/directives/views/planiaTableHeader.html'
		};
	});

	var tableHeaderController = function ($scope) {
		$scope.allRowsSelected = false;
		$scope.showFilterValues = false;

		$scope.$watch('columns', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if ($scope.columns) {
				$scope.columns.forEach(function (column) {
					if (column.Property === 'CleaningEstimateYear' || column.Property === 'EstimatedTimeYear' || column.Property === 'NextCleaning')
						column.SortingDisabled = true;
				});
			}
		});

		$scope.selectAllRows = function () {
			if ($scope.allRowsSelected) {
				$scope.table.data.forEach(function (row) {
					if (row.data) {
						row.data.forEach(function (row) {
							var index = _.findIndex($scope.list, function (o) { return $scope.useObjects ? o.Guid === row.Guid : o === row.Guid; });
							$scope.list.splice(index, 1);
						});
					}
					else {
						var index = _.findIndex($scope.list, function (o) { return $scope.useObjects ? o.Guid === row.Guid : o === row.Guid; });
						$scope.list.splice(index, 1);
					}
				});

				$scope.allRowsSelected = false;
			} else {
				$scope.table.data.forEach(function (row) {
					// Grouped table
					if (row.data) {
						row.data.forEach(function (row) {
							if ($scope.useObjects) {
								if (!_.find($scope.list, function (o) { return o.Guid === row.Guid; })) {
									$scope.list.push(row);
								}
							} else {
								if (!_.contains($scope.list, row.Guid)) {
									$scope.list.push(row.Guid);
								}
							}
						});
					}
					else {
						if ($scope.useObjects) {
							if (!_.find($scope.list, function (o) { return o.Guid === row.Guid; })) {
								$scope.list.push(row);
							}
						} else {
							if (!_.contains($scope.list, row.Guid)) {
								$scope.list.push(row.Guid);
							}
						}
					}
				});
				$scope.allRowsSelected = true;
			}
		};

		$scope.$watch('table.data', function (newValue, oldValue) {
			if (newValue === oldValue) return;

			if (newValue.length === 0) {
				$scope.allRowsSelected = false;
				return;
			}

			for (var i = 0; i < newValue.length; i++) {
				if ($scope.useObjects ? !_.find($scope.list, { 'Guid': newValue[i].Guid }) : !_.contains($scope.list, newValue[i].Guid)) {
					$scope.allRowsSelected = false;
					return;
				}
			}

			$scope.allRowsSelected = true;
		});

		$scope.sortBy = function (sortColumn) {
			if (sortColumn.SortingDisabled) return;

			if ($scope.table.sorting() && !$scope.table.sorting()[sortColumn.Property]) {
				$scope.table.$params.sorting = {};
			}

			$scope.table.sorting()[sortColumn.Property] = $scope.table.sorting()[sortColumn.Property] === 'asc' ? 'desc' : 'asc';
		};
	};
})();
