(function () {
	angular.module('Plania').controller('CleaningTaskWizardController', ['$scope', 'Repository', 'RegisteredFieldService', 'TranslationService', 'EnumService', '$filter', 'Constants', '$timeout', 'PersistentDataService', '$stateParams','$q',controller]);

	function controller($scope, repository, registeredFieldService, translationService, enumService, $filter, constants, $timeout, persistentDataService, $stateParams, $q) {
		$scope.model = {};

		registeredFieldService.getRegisteredField(repository.commonService.prefix.CleaningTask).then(function (result) {
			$scope.fieldRules = result;
		});

		if ($stateParams.showPersistantData) {
			var persistentData = persistentDataService.getPersistedData('cleaningTask.create');
			if (persistentData) {
				$scope.model.Guid = persistentData.guid;
				$scope.model.Id = persistentData.id;
				$scope.model.ExplanatoryText = persistentData.explanatoryText;
				$scope.model.Recurrence = persistentData.recurrence;
				$scope.model.RecurrenceRule = persistentData.recurrenceRule;
				$scope.model.EstimatedCost = persistentData.estimatedCost;
				$scope.model.EstimatedTime = persistentData.estimatedTime;
				$scope.model.IsTemplate = persistentData.isTemplate;
			}
		}

		/*Create steps*/
		$scope.steps = [
			{ stepNumber: 0, name: 'chooseType', hidden: true },
			{ stepNumber: 1, name: 'basicInfo', title: 'Basisinformasjon', hidden: false},
		];

		$scope.currentStep = $scope.steps[0].name;

		$scope.getCurrentStepNumber = function () {
			return _.find($scope.steps, { name: $scope.currentStep }).stepNumber;
		};

		$scope.setCurrentStep = function (step) {
			$scope.currentStep = step;
		};

		$scope.isStepDisabled = function (step) {
			return _.find($scope.steps, { name: $scope.currentStep }).stepNumber <= step.stepNumber;
		};

		$scope.isLastStep = function () {
			return $scope.currentStep === $scope.steps[$scope.steps.length - 1].name;
		};

		$scope.missingMandatoryProperties = [];

		$scope.hasError = function (field) {
			if ($scope.missingMandatoryProperties.includes(field) && !isFieldSet($scope.model[field]))
				return true;
			else if ($scope.missingMandatoryProperties.includes(field) && isFieldSet($scope.model[field])) {
				_.remove($scope.missingMandatoryProperties, function (property) { return property === field; });
				return false;
			}

			return false;
		};

		function isFieldSet(field) {
			if (typeof field === 'undefined') return false;
			if (field === null || field === '') return false;
			return true;
		}

		var basicInformationFields = ['Id', 'EstimatedTime', 'EstimatedCost', 'CleaningType', 'ExplanatoryText', 'ExitCleaningDecisionDate'];

		var sectionIsValid = function (fields) {
			$scope.missingMandatoryProperties = [];
			fields.forEach(function (field) {
				if (_.find($scope.fieldRules, { DomainFieldName: field, Mandatory: true }) && !isFieldSet($scope.model[field])) {
					$scope.missingMandatoryProperties.push(field);
				}
			});

			if ($scope.missingMandatoryProperties.length > 0) {
				return false;
			} else {
				return true;
			}
		};

		$scope.handleNextStep = function () {
			switch ($scope.currentStep) {
				case 'chooseType':
					handleCleaningType();
					$scope.currentStep = 'basicInfo';
					break;
				case 'basicInfo':
					if (sectionIsValid(basicInformationFields)) {
						if ($scope.isLastStep())
							$scope.create();
						else
							$scope.currentStep = 'recurrenceRule';
					}
					break;
				case 'recurrenceRule':
					if ($scope.isLastStep())
						$scope.create();
					break;
			}
		};

		$scope.handlePreviousStep = function () {
			$scope.missingMandatoryProperties = [];
			for (var i = 1; i < $scope.steps.length; i++) {
				var step = $scope.steps[i];
				if (step.name === $scope.currentStep) {
					$scope.currentStep = $scope.steps[i - 1].name;
					return;
				}
			}
		};

		$scope.setCleaningType = function (cleaningType) {
			$scope.model.CleaningType = cleaningType;
			if ($scope.model.RecurrenceRule) {
				if (isCleaningTypeWithoutRecurrenceRule(cleaningType)) {
					confirmRecurrenceRuleDeletion().then(function(isConfirmed) {
						if (isConfirmed) {
							$scope.model.RecurrenceRule = null;
							$scope.model.Recurrence = null;
							$scope.handleNextStep();
						}
					});
				} else {
					$scope.handleNextStep();
				}
			} else {
				$scope.handleNextStep();
			}
		};

		function isCleaningTypeWithoutRecurrenceRule(cleaningType) {
			return cleaningType === 'AdditionalCleaning' ||
				cleaningType === 'InfectionCleaning' ||
				cleaningType === 'ExitCleaning';
		}

		$scope.canSetTemplate = function() {
			return ($scope.model.CleaningType === 'AdditionalCleaning' ||
				$scope.model.CleaningType === 'InfectionCleaning' ||
				$scope.model.CleaningType === 'ExitCleaning');
		};

		var handleCleaningType = function() {
			switch ($scope.model.CleaningType) {
				case 'StandardCleaning':
					if (!$scope.model.StartDate)
						$scope.model.StartDate = new Date();
					if (!$scope.model.RecurrenceRule) {
						$scope.model.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Day'
						};
					}
					$scope.steps.push({ stepNumber: 2, name: 'recurrenceRule', title: 'Gjentakelsesregel', hidden: false });
					break;
				case 'ExitCleaning':
					var date = new Date();
					date.setHours(12, 0, 0);
					$scope.model.ExitCleaningDecisionDate = date;
					break;
				case 'PeriodicCleaning':
					if (!$scope.model.StartDate)
						$scope.model.StartDate = new Date();

					if (!$scope.model.RecurrenceRule) {
						$scope.model.RecurrenceRule = {
							StartDate: new Date(),
							Unit: 'Once',
							PeriodNumber: 1,
							PeriodUnit: 'Day'
						};
					}

					$scope.steps.push({ stepNumber: 2, name: 'recurrenceRule', title: 'Gjentakelsesregel', hidden: false });
					break;
				case 'InfectionCleaning':
					$scope.model.RecurrenceRule = null;
					break;
				case 'AdditionalCleaning':
					$scope.model.RecurrenceRule = null;
					break;
			}
		};

		function confirmRecurrenceRuleDeletion() {
			var deferred = $q.defer();
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-cleaningTask-wizard-recurrenceRemove',
					"Det eksisterer en gjentakelseregel på oppgaven som ikke er gyldig for valgt renholdstype. Gjentakelseregel vil bli slettet fra oppgaven og tilhørende rom."),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-cleaningTask-wizard-recurrenceRemove-confirm', 'Ja, fjern gjentakelseregel'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: true,
				closeOnCancel: true
			}, function (isConfirm) {
				window.onkeydown = null;
				window.onfocus = null;

				deferred.resolve(isConfirm);
			});
			return deferred.promise;
		}

		$scope.create = function () {
			if ($scope.isSaving) return;
			$scope.isSaving = true;

			if (!$scope.canSetTemplate())
				$scope.model.IsTemplate = false;

			var toSave = angular.copy($scope.model);

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.StartDate)
				toSave.RecurrenceRule.StartDate = new Date(toSave.RecurrenceRule.StartDate).toISOString();

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.Until)
				toSave.RecurrenceRule.Until = new Date(toSave.RecurrenceRule.Until).toISOString();

			if (toSave.RecurrenceRule && toSave.RecurrenceRule.Unit) {
				enumService.setTimeUnitOnRecurrence(toSave.RecurrenceRule);
			}

			if (toSave.ExitCleaningDecisionDate)
				toSave.ExitCleaningDecisionDate = new Date(toSave.ExitCleaningDecisionDate).toISOString();

			// Temp for testing
			toSave.NumericFieldName = 'COMPUTED_NET_AREA';

			var success = function (result) {
				repository.growl(translationService.translate('create-cleaningTask-success', 'Renholdsoppgaven har blitt opprettet.'), 'success');
				var guid = result.Guid;
				repository.commonService.setLastRegisterGuid('cleaningTask.edit', guid);
				if ($stateParams.showPersistantData)
					persistentDataService.clearPersistedData('cleaningTask.create');
				$scope.goBack('cleaningTask.edit', { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
				$scope.isSaving = false;
			};

			if (toSave.Guid) {
				repository.updateSingleDictionary(repository.apiData.cleaningTask.url, toSave).then(success, error);
			} else {
				repository.createSingleDictionary(repository.apiData.cleaningTask.url, toSave).then(success, error);
			}
		};
		
	}
})();
