(function () {
	angular.module('Plania').controller('ProjectPhaseSetupController', ['$scope', 'Repository', '$stateParams', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, translationService) {

		var columns = ['Project.Guid', 'Project.Id', 'Project.Description', 'Project.GuidProjectStatus', 'Project.IsTemplate', 'Project.ClosedDate', 'Id', 'Description', 'IndexPosition', 'GuidProject'];

		$scope.project = { Guid: $stateParams.guid };

		repository.GetPaginated(repository.apiData.projectPhase.url,
			0,
			-1,
			{ 'IndexPosition': 'asc' },
			{ PropertyFilter: [{ Property: 'GuidProject', Operator: '=', 'Value': $stateParams.guid }] }, null, JSON.stringify(columns))
			.then(function (result) {
				$scope.ProjectPhases = result.List;
				if (result.List.length > 0) {
					var first = result.List[0];
					$scope.project = first.Project;
				}
			});

		$scope.selectedItem = {};

		$scope.clickAction = function (item, phase) {
			if (item.Prefix === 'ProjectStatus') {
				$scope.activePhase = phase;
			}
			$scope.selectedItem = item;
		};

		$scope.treeOptions = {
			accept: function (sourceNodeScope, destNodeScope, destIndex) {
				return destNodeScope.$modelValue.some(
					function (s) { return s.Guid === sourceNodeScope.$modelValue.Guid; });
			}
		};


		//phase management

		$scope.IsActivePhase = function (phase) {
			return phase.ProjectStatuses.some(function(status) {
				return status.Guid === $scope.project.GuidProjectStatus;
			});
		};

		$scope.copyPhase = function(phase) {
			var newPhase = angular.copy(phase);
			newPhase.IndexPosition = $scope.ProjectPhases.length;
			delete newPhase.Guid;
			newPhase.ProjectStatuses.forEach(function(status) {
				delete status.Guid;
			});
			$scope.ProjectPhases.push(newPhase);
		};

		$scope.addPhase = function () {
			var maxPosition = 0;
			if ($scope.ProjectPhases && $scope.ProjectPhases.length)
				maxPosition = _.max($scope.ProjectPhases, function(phase) { return phase.IndexPosition; });
			$scope.selectedItem = {
				Id: '',
				Description: '',
				Prefix: 'ProjectPhase',
				ProjectStatuses: [],
				IndexPosition: ++maxPosition,
				isNew: true
			};
		};
		//end phase management

		$scope.updateRemovedStatus = function (item) {

			if (item.Prefix === 'ProjectStatus') {
				if ($scope.activePhase.removed) {
					item.removed = true;
					return;
				}

			}

			if (item.removed)
				item.removed = false;
			else
				item.removed = true;

			if (item.Prefix === 'ProjectPhase') {
				item.ProjectStatuses.forEach(function (status) {
					status.removed = item.removed;
				});
			}

		};


		$scope.addStatus = function (phase) {
			var maxPosition = 0;
			if ($scope.ProjectStatuses && $scope.ProjectStatuses.length)
				maxPosition = _.max($scope.ProjectStatuses, function (status) { return status.IndexPosition; });
			$scope.selectedItem = {
				Id: '',
				Description: '',
				Stage: 'Active',
				Prefix: 'ProjectStatus',
				IndexPosition: ++maxPosition,
				isNew: true,
				phase: phase
			};
		};
		//end status management


		$scope.saveNewItem = function () {
			if ($scope.selectedItem.Prefix === 'ProjectStatus') {
				$scope.selectedItem.phase.ProjectStatuses.push($scope.selectedItem);
				$scope.selectedItem.isNew = false;
				delete $scope.selectedItem.phase;
			}
			if ($scope.selectedItem.Prefix === 'ProjectPhase') {
				$scope.ProjectPhases.push($scope.selectedItem);
				$scope.selectedItem.isNew = false;
			}
		};

		$scope.savePhaseSetup = function (destination) {
			_.remove($scope.ProjectPhases, function (phase) { return phase.removed; });
			var indexPosition = 0;
			for (var i = 0; i < $scope.ProjectPhases.length; i++) {
				var phase = $scope.ProjectPhases[i];
				if (phase.removed) {
					continue;
				}
				phase.IndexPosition = indexPosition++;
				var statusIndexPosition = 0;
				_.remove(phase.ProjectStatuses, function (status) { return status.removed; });
				for (var j = 0; j < phase.ProjectStatuses.length; j++) {
					var status = phase.ProjectStatuses[j];
					if (status.removed) {
						continue;
					}
					status.IndexPosition = statusIndexPosition++;
				}
			}

			repository.updateMultipleWithGuid(repository.apiData.projectPhase.setupUrl, $scope.project.Guid, JSON.stringify($scope.ProjectPhases)).then(function(result) {
				repository.growl(translationService.translate('web-phaseSetup-updated-info', 'Faser og statuser har blitt lagret'));
				if (destination !== 'project.editPhase') {
					$scope.goBack(destination, { guid: $scope.project.Guid, menuGuid: $scope.navigation.params.menuGuid });
				}
			});

		};
	}
})();
