(function () {
    angular.module('Plania').controller('AddNewLinkModalController', ['$scope', '$modalInstance', 'link', controller]);

	function controller($scope, modalInstance, link) {
		$scope.isUpdate = !!link;

        if (link) {
            $scope.model = link;
        } else {
            $scope.model = {};
        }

        $scope.save = function () {
            if ($scope.model.IsExternalLink && $scope.model.Url.indexOf('http') !== 0) {
                $scope.model.Url = 'https://' + $scope.model.Url;
            }

            modalInstance.close($scope.model);
        };

		$scope.cancel = function () {
			modalInstance.dismiss("cancel");
		};
    }
})();
