(function () {
	angular.module('Plania').controller('EquipmentOperatingHoursDashboardController', ['$scope', 'Repository', '$localStorage', 'RegisteredFieldService', '$modal', 'TranslationService', controller]);

	function controller($scope, repository, $localStorage, registeredFieldService, $modal, translationService) {

		var defaultEquipmentOperatingHourType = "Strøm";

		$scope.dropdown = {
			EquipmentOperatingHourType: { Id: "Ikke valgt", Guid: null },
			EquipmentOperatingHourTypes: [{ Id: "Ikke valgt", Guid: null }],
			BuildingCategory: { Description: "Ikke valgt", Guid: null },
			BuildingCategories: [{ Description: "Ikke valgt", Guid: null }],
			EquipmentCategory: { Description: "Ikke valgt", Guid: null },
			EquipmentCategories: [{ Description: "Ikke valgt", Guid: null }],
		};
		$scope.showEquipmentOperatingHourTypeDropdown = false;

		$scope.availableYears = [];
		$scope.endYear = new Date().getFullYear();
		$scope.startYear = $scope.endYear - 1;
		for (var i = $scope.endYear; i > $scope.endYear - 100; i--)
			$scope.availableYears.push(i);

		var getDropdownData = function () {
			$scope.dropdown.EquipmentOperatingHourType = { Id: "Ikke valgt", Guid: null };
			$scope.dropdown.BuildingCategory = { Description: "Ikke valgt", Guid: null };
			$scope.dropdown.EquipmentCategory = { Description: "Ikke valgt", Guid: null };

			registeredFieldService.getFieldRule(repository.commonService.prefix.EquipmentOperatingHours, 'GuidEquipmentOperatingHourType').then(function (result) {
				if (result.Hidden || (result.ExternallyHidden && $localStorage.userData.isExternalUser)) {
					$scope.getChartData();
					return;
				} else {
					$scope.showEquipmentOperatingHourTypeDropdown = true;
					repository.GetPaginated(repository.apiData.equipmentOperatingHourType.url, 0, 20, { 'Id': 'asc' }).then(function (result) {
						$scope.dropdown.EquipmentOperatingHourTypes = [{ Id: "Ikke valgt", Guid: null }].concat(result.List);
						if ($scope.dropdown.EquipmentOperatingHourTypes.length > 1) {
							// TODO find smarter way to find default hour type
							var defaultEnergy = _.find($scope.dropdown.EquipmentOperatingHourTypes, { Id: defaultEquipmentOperatingHourType });
							$scope.dropdown.EquipmentOperatingHourType = defaultEnergy || $scope.dropdown.EquipmentOperatingHourTypes[1];
						}
						$scope.getChartData();
					});
				}
			});

			repository.GetPaginated(repository.apiData.buildingCategory.url, 0, 20, { 'Description': 'asc' }).then(function (result) {
				$scope.dropdown.BuildingCategories = [{ Description: "Ikke valgt", Guid: null }].concat(result.List);
			});

			repository.GetPaginated(repository.apiData.equipmentCategory.url, 0, 20, { 'Description': 'asc' }).then(function (result) {
				$scope.dropdown.EquipmentCategories = [{ Description: "Ikke valgt", Guid: null }].concat(result.List);
			});
		};
		getDropdownData();

		var getMonthNameByIndex = function (monthIndex) {
			var monthName = "Jan";
			if (monthIndex === "2" || monthIndex === 2) monthName = "Feb";
			if (monthIndex === "3" || monthIndex === 3) monthName = "Mar";
			if (monthIndex === "4" || monthIndex === 4) monthName = "Apr";
			if (monthIndex === "5" || monthIndex === 5) monthName = "Mai";
			if (monthIndex === "6" || monthIndex === 6) monthName = "Jun";
			if (monthIndex === "7" || monthIndex === 7) monthName = "Jul";
			if (monthIndex === "8" || monthIndex === 8) monthName = "Aug";
			if (monthIndex === "9" || monthIndex === 9) monthName = "Sept";
			if (monthIndex === "10" || monthIndex === 10) monthName = "Okt";
			if (monthIndex === "11" || monthIndex === 11) monthName = "Nov";
			if (monthIndex === "12" || monthIndex === 12) monthName = "Des";
			return monthName;
		};

		$scope.formatLabel = function (sender) {
			var labelText = sender.data.label.Text;
			if (labelText >= 1000000) {
				sender.data.label.Text = labelText / 1000000 + "M";
			} else if (labelText >= 1000) {
				sender.data.label.Text = labelText / 1000 + "K";
			}
		};

		// Todo - implement in UI. Must enableCanvasRendering=true
		$scope.downloadClientside = function (id, type) {
			var chart = $("#" + id).ejChart("instance");
			var exporting = chart.model.exportSettings;

			exporting.fileName = chart.model.title + new Date().toISOString();
			exporting.angle = 0;
			exporting.type = type || "png";
			exporting.mode = "client";
			var data = chart.export();
			if (window.navigator.msSaveOrOpenBlob) {
				var blob;
				if (type === "png")
					blob = data.msToBlob();
				else if (type === "jpg")
					blob = data.msToBlob(null, "image/jpeg");
				else if (type === "svg") {
					data = decodeURIComponent(data);
					blob = new Blob([data], { type: "image/svg-xml" });
				}
				window.navigator.msSaveOrOpenBlob(blob, exporting.fileName + "." + type);
			}
			else {
				this.download = exporting.fileName + "." + type;
				if (type === "png")
					this.href = data.toDataURL();
				else if (type === "jpg")
					this.href = data.toDataURL("image/jpeg");
				else
					this.href = "data:text/plain;charset=utf-8," + data;
			}
		};

		var getCommonParams = function () {
			var params = "";
			params += "fromYear=" + $scope.startYear;
			params += "&toYear=" + $scope.endYear;

			if ($scope.dropdown.EquipmentOperatingHourType && $scope.dropdown.EquipmentOperatingHourType.Guid)
				params += "&guidEquipmentOperatingHourType=" + $scope.dropdown.EquipmentOperatingHourType.Guid;
			if ($scope.dropdown.BuildingCategory && $scope.dropdown.BuildingCategory.Guid)
				params += "&guidBuildingCategory=" + $scope.dropdown.BuildingCategory.Guid;
			if ($scope.dropdown.EquipmentCategory && $scope.dropdown.EquipmentCategory.Guid)
				params += "&guidEquipmentCategory=" + $scope.dropdown.BuildingCategory.Guid;

			var filter = repository.commonService.getFilterData();
			if (filter.selectedBuilding && filter.selectedBuilding.Guid)
				params += "&guidBuilding=" + filter.selectedBuilding.Guid;
			if (filter.selectedEstate && filter.selectedEstate.Guid)
				params += "&guidEstate=" + filter.selectedEstate.Guid;
			if (filter.selectedSelection && filter.selectedSelection.Guid)
				params += "&guidSelection=" + filter.selectedSelection.Guid;
			return params;
		};

		$scope.monthlyConsumptionChart = new function () {
			var me = this;
			this.columnSeries = [];
			this.lineSeries = [];
			this.chartData = [];
			this.chartSettings = {
				legend: {
					visible: true,
					shape: 'seriesType',
					position: 'top',
					itemStyle: { width: 15, height: 15 },
					font: { size: '15px' }
				},
				monthlyConsumptionChartTitle: {
					text: "Forbruk per måned"
				},
				monthlyConsumptionPerAreaChartTitle: {
					text: "Forbruk per måned fordelt på areal"
				},
				size: {
					height: "600",
					width: "100%"
				},
				commonSeriesOptions: {
					tooltip: { visible: true }
				},
				primaryXAxis: {
					title: {
						text: "Måned"
					}
				},
				primaryYAxis: {
					title: {
						text: "Forbruk"
					}
				},
				axes: [{
					majorGridLines: { visible: false },
					orientation: 'Vertical',
					opposedPosition: true,
					axisLine: { visible: false },
					rangePadding: 'normal',
					name: 'yAxis',
					title: { text: "Akkumulert" }
				}],
				combinationmarker: { shape: 'circle', size: { height: 8, width: 8 }, visible: true },
				combinationborder: { width: 2 },
			};

			this.updateChart = function () {
				var chart = $("#monthlyConsumptionChart").ejChart("instance");
				var chart2 = $("#monthlyConsumptionPerAreaChart").ejChart("instance");
				chart.model.legend.columnCount = me.columnSeries.length;
				chart2.model.legend.columnCount = me.columnSeries.length;

				chart.model.primaryYAxis.visibleLabels.forEach(function (label) {
					label.displayText = label.displayText + "k";
					label.Text = label.Text + "k";
					label.Value = label.Value + "k";
					label.region.labelText = label.region.labelText + "k";
					label.region.trimText = label.region.trimText + "k";
				});


				var columnSeriesTitle = $scope.dropdown.EquipmentOperatingHourType.Guid ? $scope.dropdown.EquipmentOperatingHourType.Id : "Forbruk";
				chart.model.primaryYAxis.title.text = columnSeriesTitle;
				chart2.model.primaryYAxis.title.text = columnSeriesTitle;

				var lineSeriesTitle = "Akkumulert";
				chart.model.axes[0].title.text = lineSeriesTitle;
				chart2.model.axes[0].title.text = lineSeriesTitle;

				chart.model.series = [];
				chart2.model.series = [];
				me.columnSeries.forEach(function (series) {
					chart.model.series.push({
						dataSource: me.chartData,
						name: series.name,
						xName: series.xName,
						yName: series.yName,
						type: 'column',
						tooltip: {
							format: '#point.x# - #series.name# <br/>' + columnSeriesTitle + ' :   ej.format(#point.y#,n0) <br/>'
						}
					});

					chart2.model.series.push({
						dataSource: me.chartData,
						name: series.name,
						xName: series.xName,
						yName: series.yName2,
						type: 'column',
						tooltip: {
							format: '#point.x# - #series.name# <br/>' + columnSeriesTitle + ' :  ej.format(#point.y#,n0) <br/>'
						}
					});
				});

				me.lineSeries.forEach(function (series) {
					chart.model.series.push({
						dataSource: me.chartData,
						name: series.name,
						xName: series.xName,
						yName: series.yName,
						type: 'line',
						tooltip: {
							format: '#point.x# - #series.name# <br/>' + lineSeriesTitle + ' :  ej.format(#point.y#,n0) <br/>'
						},
						yAxisName: 'yAxis',
						marker: me.chartSettings.combinationmarker,
						border: me.chartSettings.combinationborder
					});

					chart2.model.series.push({
						dataSource: me.chartData,
						name: series.name,
						xName: series.xName,
						yName: series.yName2,
						type: 'line',
						tooltip: {
							format: '#point.x# - #series.name# <br/>' + lineSeriesTitle + ' :  ej.format(#point.y#,n0) <br/>'
						},
						yAxisName: 'yAxis',
						marker: me.chartSettings.combinationmarker,
						border: me.chartSettings.combinationborder
					});
				});
				chart.redraw();
				chart2.redraw();
			};

			this.getChartData = function () {
				var params = getCommonParams();

				repository.getWithUrlParameter(repository.apiData.equipmentOperatingHours.endpoint.consumption, params).then(function (result) {
					var chartData = [];
					var grouped = _.groupBy(result, 'Month');

					Object.keys(grouped).forEach(function (month) {
						var newData = {
							month: getMonthNameByIndex(month),
						};
						grouped[month].forEach(function (d) {
							newData["value" + d.Year] = d.Value ? d.Value : undefined;
							newData["accValue" + d.Year] = d.AccumulatedValue ? d.AccumulatedValue : undefined;
							newData["valuePerArea" + d.Year] = d.ValuePerArea ? d.ValuePerArea : undefined;
							newData["accValuePerArea" + d.Year] = d.AccumulatedValuePerArea ? d.AccumulatedValuePerArea : undefined;
						});
						chartData.push(newData);
					});

					var yearlyGrouped = _.groupBy(result, 'Year');
					var lineSeries = [];
					var columnSeries = [];
					Object.keys(yearlyGrouped).forEach(function (year) {
						lineSeries.push({
							name: year + " (Akk)",
							xName: 'month',
							yName: 'accValue' + year,
							yName2: 'accValuePerArea' + year
						});
						columnSeries.push({
							name: year,
							xName: 'month',
							yName: 'value' + year,
							yName2: 'valuePerArea' + year
						});
					});
					me.columnSeries = columnSeries;
					me.lineSeries = lineSeries;
					me.chartData = chartData;
					me.updateChart();
				});
			};
		}();

		$scope.buildingSummaryChart = new function () {
			var me = this;
			this.chartData = [];
			this.chartSettings = {
				legend: {
					visible: false
				},
				buildingSummaryChartTitle: {
					text: "Forbruk per bygg"
				},
				buildingSummaryChartPerAreaChartTitle: {
					text: "Forbruk per bygg fordelt på areal"
				},
				size: {
					width: "100%"
				},
				commonSeriesOptions: {
					tooltip: { visible: true }
				},
				primaryXAxis: {
					title: {
						text: "Bygg"
					}
				},
				primaryYAxis: {
					title: {
						text: "Forbruk"
					},
				}
			};

			this.updateChart = function () {
				var chart = $("#buildingSummaryChart").ejChart("instance");
				var chart2 = $("#buildingSummaryChartPerAreaChart").ejChart("instance");

				var titleText = $scope.dropdown.EquipmentOperatingHourType.Guid ? $scope.dropdown.EquipmentOperatingHourType.Id : "Forbruk";
				chart.model.primaryYAxis.title.text = titleText;
				chart2.model.primaryYAxis.title.text = titleText;

				chart.model.series = [
					{
						dataSource: _.sortBy(me.chartData, 'Value'),
						name: "Bygg",
						xName: "Caption",
						yName: "Value",
						type: 'bar',
						tooltip: {
							format: '#point.x# <br/>' + titleText + ' :  ej.format(#point.y#,n0) <br/>'
						}
					},
				];
				chart2.model.series = [
					{
						dataSource: _.sortBy(me.chartData, 'ValuePerArea'),
						name: "Bygg",
						xName: "Caption",
						yName: "ValuePerArea",
						type: 'bar',
						tooltip: {
							format: '#point.x# <br/>' + titleText + ' :  ej.format(#point.y#,n0) <br/>'
						}
					}
				];

				chart.model.size.height = 100 + (40 * (me.chartData.length + 1));
				chart2.model.size.height = 100 + (40 * (me.chartData.length + 1));

				chart.redraw();
				chart2.redraw();
			};

			this.getChartData = function () {
				var params = getCommonParams();
				repository.getWithUrlParameter(repository.apiData.equipmentOperatingHours.endpoint.buildingSummary, params).then(function (result) {
					result.forEach(function (r) {
						r.Caption = [r.Id, r.Description].join(" - ");
					});
					me.chartData = result;
					me.updateChart();
				});
			};
		}();

		$scope.getChartData = function () {
			$scope.monthlyConsumptionChart.getChartData();
			$scope.buildingSummaryChart.getChartData();
		};

		$scope.$on($scope.events.newSelection, function () {
			getDropdownData();
		});
	}
})();
