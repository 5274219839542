(function () {
	angular.module('Plania').controller('SupplierController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'authService', 'TranslationService', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, authService, translationService) {
		$scope.update = $scope.navigation.current.name === 'supplier.edit';
		$scope.isPersonCollapsed = true;
		$scope.restrictEdit = !authService.hasEditAccess('supplier');
		$scope.restrictDelete = !authService.hasDeleteAccess('supplier');
		$scope.model = { Guid: $stateParams.guid };
		$scope.modelDto = {};
		$scope.reload = false;
		$scope.activateAutocomplete = false;
		$scope.viewMode = $scope.update ? true : false;

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		var updateAccess = function () {
			if ($scope.update) {
				var checkOtherDo = $scope.model.GuidDataOwner !== repository.commonService.getFilterData().selectedDataOwner.Guid;
				$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Supplier, checkOtherDo);
				$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Supplier, checkOtherDo);
			} else {
				$scope.restrictEdit = !authService.hasCreateAccess(repository.commonService.prefix.Supplier);
			}
		};
		updateAccess();


		if ($scope.update) {
			var columns = ['SupplierLineOfBusiness.Id'];
			repository.getMainModel(repository.apiData.supplier.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var supplier = response.Data;
					$scope.model = supplier;
					updateAccess();
					$scope.reload = true;
					if ($scope.model.SupplierLineOfBusiness)
						$scope.modelDto.supplierLineOfBusiness = $scope.getEntityCaption($scope.model.SupplierLineOfBusiness);

					$scope.contactTable.filter().GuidSupplier = $scope.model.Guid;
					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});

		} else {

			$scope.activateAutocomplete = true;

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'SupplierLineOfBusiness':
								$scope.model.SupplierLineOfBusiness.Id = prefill.Value.Label;
								$scope.model.GuidSupplierLineOfBusiness = prefill.Value.Guid;
								break;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}
		}

		$scope.contactTable = new ngTableParams({
			page: 1, count: 10,
			sorting: { last_name: 'asc', first_name: 'asc' },
			filter: { PropertyFilter: [{ Property: 'GuidSupplier', Operator: '=', Value: $stateParams.guid }] }
		},
			{
				total: 0,
				filterDelay: 10,
				counts: [],
				getData: function ($defer, params) {
					if (!authService.hasReadAccess('ContactPerson')) return;

					if (!params.filter().GuidSupplier)
						return;
					repository.GetPaginated(repository.apiData.contactPerson.url, params.page() - 1, params.count(), params.sorting(), params.filter())
						.then(function (result) {
							$scope.contactTable.settings().total = result.TotalCount;
							$scope.contactTable.settings().filterDelay = 500;
							if (result.totalCount > 10) {
								params.counts([10, 20, 50]);
							}
							$defer.resolve(result.List);
						},
						function (error) {
							repository.growl(error, 'danger');
						});
				}
			});


		$scope.updateSupplier = function (destination) {
			var success = function (result) {
				repository.growl($scope.update ? 'Leverandør har blitt oppdatert' : 'Leverandør har blitt lagret', 'success');
				var guid = $scope.update ? $scope.model.Guid : result.Guid;
				repository.commonService.setLastRegisterGuid(destination, guid);
				$scope.goBack(destination, { guid: guid, menuGuid: $scope.navigation.params.menuGuid });
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update)
				repository.updateSingleDictionary(repository.apiData.supplier.url, $scope.model).then(success).catch(error);
			else
				repository.createSingleDictionary(repository.apiData.supplier.url, $scope.model).then(success).catch(error);
		};

		$scope.addContactPerson = function () {
			$modal.open({
				templateUrl: 'app/contactPerson/contactPerson.html',
				controller: 'ContactPersonController',
				resolve: {
					params: function () { return { guidSupplier: $scope.model.Guid }; }
				}
			}).result.then(function () {
				$scope.contactTable.reload();
				$scope.isPersonCollapsed = false;
			},
				function () {
					$scope.isPersonCollapsed = false;
				});
		};

		$scope.updateContactPerson = function (guid) {
			$modal.open({
				templateUrl: 'app/contactPerson/contactPerson.html',
				controller: 'ContactPersonController',
				resolve: {
					params: function () { return { guid: guid }; }
				}
			}).result.then(function () {
				$scope.contactTable.reload();
				$scope.isPersonCollapsed = false;
			},
				function () {
					//cancel
					$scope.isPersonCollapsed = false;
				});
		};

		$scope.openUrl = function () {
			var url = $scope.model.WebAddress;
			if (url && url.indexOf('http') !== 0) {
				url = 'https://' + url;
			}
			window.open(url, '_blank');
		};

		$scope.openMail = function () {
			window.location.href = 'mailto:' + $scope.model.Email;
		};

		$scope.removePerson = function (person) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-supplier-contactperson-message', 'Kontaktpersonen vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-supplier-contactperson-button-confirm', 'Ja, fjern kontaktpersonen!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.contactPerson.url, person.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-supplier-contactperson-success', 'Kontaktpersonen ble fjernet!'), result, "success");
						$scope.contactTable.reload();
					});
			});
		};

		$scope.removeSupplier = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-supplier-message', 'Leverandøren vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-supplier-button-confirm', 'Ja, fjern leverandøren!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.supplier.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-supplier-success', 'Leverandøren ble fjernet!'), result, "success");
						$scope.goBack('supplier.list', { menuGuid: $scope.navigation.params.menuGuid });
					}, function (error) {
						swal({
							title: "Kunne ikke slette leverandøren!",
							text: error.Message + "<br/><br/> Vil du deaktivere leverandøren?",
							type: "error",
							html: true,
							showCancelButton: true,
							confirmButtonText: "Deaktiver",
							cancelButtonText: "Avbryt",
							closeOnConfirm: true,
							closeOnCancel: true
						}, function (isConfirm) {
							window.onkeydown = null;
							window.onfocus = null;
							if (isConfirm) {
								$scope.model.IsDeactivated = true;
								$scope.model.Id = '#' + $scope.model.Id;
								$scope.updateSupplier('supplier.list');
							}
						});
					});
			});
		};

		$scope.onPostCodeSelect = function (postalInfo) {
			if (!postalInfo) {
				$scope.model.PostalCode = '';
				$scope.model.GuidPost = '';
				$scope.model.PostalArea = '';
			} else {
				$scope.model.GuidPost = postalInfo.Guid;
				$scope.model.PostalCode = postalInfo.ZipCode;
				$scope.model.PostalArea = postalInfo.PostalArea;
				$scope.postalInfo = postalInfo;
			}
		};

		$scope.$on($scope.events.newSelection, function () {
			updateAccess();
		});
	}
})();
