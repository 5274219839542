(function () {
	angular.module('Plania').controller('AreaController', ['$scope', 'Repository', '$stateParams', 'ngTableParams', '$modal', 'authService', '$modalStack', 'TranslationService', 'ListService', 'EnumService', '$state', controller]);

	function controller($scope, repository, $stateParams, ngTableParams, $modal, authService, $modalStack, translationService, listService, enumService, $state) {
		$scope.update = $scope.navigation.current.name !== 'area.create';
		$scope.activateAutocomplete = false;
		$scope.isModal = $stateParams.isModal;
		$scope.restrictEdit = $scope.update ? !authService.hasEditAccess(repository.commonService.prefix.Area, false) : !authService.hasCreateAccess('area');
		$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Area, false);
		$scope.model = $stateParams.model || { Guid: $stateParams.guid };
		$scope.DTO = {};
		$scope.barcode = {};
		$scope.viewMode = $scope.update ? true : false;
		$scope.reloads = {
			deviations: false,
			controlLists: false,
			persons: false
		};
		$scope.modelDto = {};

		$scope.componentSearch = {};

		$scope.toggleViewMode = function () {
			$scope.viewMode = !$scope.viewMode;
			$scope.reload = true;
		};

		$scope.autoCompleteFilter = function (filterName) {
			var filter = {};
			switch (filterName) {
				case "person":
					filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
					if ($scope.model.GuidCleaningTeam)
						filter.PropertyFilter.push({ Property: 'GuidResourceGroup', Value: $scope.model.GuidCleaningTeam, Operator: '=' });
					return filter;
				case "resourceGroup":
					filter.PropertyFilter = [{ Property: 'DoesCleaningTasks', Value: 1, Operator: '=' }];
					return filter;
				case "drawing":
					return { GuidBuilding: $scope.model.GuidBuilding };
			}
		};

		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};

		$scope.componentColumns = [
			{ Position: 1, Title: translationService.translate('web-component-id'), Property: 'Component.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-component-description'), Property: 'Component.Description', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-componentCategory-description'), Property: 'Component.ComponentCategory.Description', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-componentxequipment-quantity', 'Antall'), Property: 'Quantity', PropertyType: 'string' },
		];

		$scope.cleaningTaskColumns = [
			{ Position: 1, Title: translationService.translate('web-areaxcleaningtask-cleaningTask-id', 'Renholdsoppgave'), Property: 'CleaningTask.Id', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-areaxcleaningtask-ResourceGroup-id', 'Renholdsteam'), Property: 'ResourceGroup.Id', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-areaxcleaningtask-AverageCleaningPerYear', 'Minutter neste år'), Property: 'AverageCleaningPerYear', PropertyType: 'number'},
			{ Position: 4, Title: translationService.translate('web-areaxcleaningtask-Recurrence', 'Gjentakelsesregel'), Property: 'Recurrence', PropertyType: 'recurrence' },
			{ Position: 5, Title: translationService.translate('web-areaxcleaningtask-NextCleaningDate', 'Utføres'), Property: 'NextCleaningDate', PropertyType: 'date' }
		];

		$scope.workOrderColumns = [
			{ Position: 1, Title: translationService.translate('web-workorder-id'), Property: 'Id', PropertyType: 'string' },
			{ Position: 1, Title: translationService.translate('web-workorder-type'), Property: 'Type', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-workorder-description'), Property: 'Description', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-equipment-resourcegroup-description', 'Ressurs'), Property: 'ResourceGroup.Id', PropertyType: 'autocomplete', AutocompleteProperty: 'GuidResourceGroup', AutocompleteService: 'resourceGroup' },
			{ Position: 6, Title: translationService.translate('web-workorder-startdate'), Property: 'StartDate', PropertyType: 'date' },
			{ Position: 7, Title: translationService.translate('web-workorder-enddate'), Property: 'EndDate', PropertyType: 'date' },
		];

		$scope.personColumns = [
			{ Position: 1, Title: translationService.translate('web-person-firstname'), Property: 'FirstName', PropertyType: 'string' },
			{ Position: 2, Title: translationService.translate('web-person-lastname'), Property: 'LastName', PropertyType: 'string' },
			{ Position: 3, Title: translationService.translate('web-person-email'), Property: 'Email', PropertyType: 'string' },
			{ Position: 4, Title: translationService.translate('web-person-position'), Property: 'Position', PropertyType: 'string' },
			{ Position: 5, Title: translationService.translate('web-person-telephonework'), Property: 'TelephoneWork', PropertyType: 'string' },
			{ Position: 6, Title: translationService.translate('web-person-cellphone'), Property: 'Cellphone', PropertyType: 'string' }
		];


		$scope.getNextCleaningDateString = function (task) {
			return listService.getNextCleaningDateString(task, true);
		};

		var getCardTables = function () {
			$scope.cleaningTaskTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'CleaningTask.Id': 'asc' },
				filter: {
					PropertyFilter: [{ Property: 'GuidArea', Operator: '=', Value: $stateParams.guid }],
					CleaningStatus: 'Undefined'
				}
			}, {
					total: 0,
					counts: [10, 20, 50],
					filterDelay: 10,
					getData: function ($defer, params) {
						$scope.loadingCleaningTasks = true;
						var columns = _.map($scope.cleaningTaskColumns, 'Property');
						columns.push('PausedFromDate', 'PausedToDate');
						repository.GetPaginated(repository.apiData.areaXCleaningTask.url, params.page() - 1, params.count(), params.sorting(), params.filter(), "", JSON.stringify(columns))
							.then(function (result) {
								$scope.loadingCleaningTasks = false;
								$scope.cleaningTaskTable.settings().total = result.TotalCount;
								$scope.cleaningTaskTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								$scope.loadingCleaningTasks = false;
								repository.growl(error, 'danger');
							});
					}
				});

			$scope.componentTable = new ngTableParams(
				{
					page: 1,
					count: 25,
					sorting: { 'Component.Id': 'asc' },
					filter: { PropertyFilter: [{ Property: "GuidArea", Operator: "=", Value: $scope.model.Guid }] }
				},
				{
					total: 0,
					filterDelay: 10,
					counts: [10, 20, 50],
					paginationMaxBlocks: 6,
					getData: function ($defer, params) {

						var columns = [];
						$scope.componentColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						columns.push('Note');
						columns.push('Component.Guid');
						columns.push('Component.GuidImage');

						repository.GetPaginated(repository.apiData.componentArea.url,
							params.page() - 1,
							params.count(),
							params.sorting(),
							params.filter(),
							null,
							JSON.stringify(columns))
							.then(function (result) {
								$scope.componentTable.settings().total = result.TotalCount;
								$scope.componentTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							},
							function (error) {
								repository.growl(error, 'danger');
							});
					}
				});
		};

		if ($scope.update) {
			var columns = [
				'Building.Guid',
				'Building.Id',
				'Building.Description',
				'Building.Estate.Id',
				'Building.Estate.Description',
				'Building.Estate.Guid',
				'Drawing.Guid',
				'Drawing.Id',
				'Drawing.Description',
				'AreaCategory.Description',
				'AreaType.Id',
				'AreaType.Description',
				'CleaningQuality.Id',
				'CleaningQuality.Description',
				'ResourceGroup.Id',
				'ResourceGroup.Description',
				'Person.FirstName',
				'Person.LastName',
				'Organization.Id',
				'Organization.Description',
			];

			repository.getMainModel(repository.apiData.area.url, $stateParams.guid, JSON.stringify(columns)).then(
				function (response) {
					var area = response.Data;
					if (area.IsHousing) {
						// move to correct edit page, and use location "replace" to avoid having this page in browser history
						$state.go('housing.edit', { guid: $stateParams.guid, model: area }, { location: 'replace' });
						return;
					}
					area.ComputedGrossArea = JSON.stringify(area.ComputedGrossArea).replace('.', ',');
					area.ComputedNetArea = JSON.stringify(area.ComputedNetArea).replace('.', ',');
					$scope.model = area;
					$scope.showDynamicProperties = $scope.model.DynamicProperty && $scope.model.DynamicProperty.Groups.length > 0;
					var checkOtherDataOwner = repository.commonService.getFilterData().selectedDataOwner.Guid !== $scope.model.GuidDataOwner;
					$scope.restrictEdit = !authService.hasEditAccess(repository.commonService.prefix.Area, checkOtherDataOwner);
					$scope.restrictDelete = !authService.hasDeleteAccess(repository.commonService.prefix.Area, checkOtherDataOwner);

					if (area.Building) {
						$scope.modelDto.building = area.Building.Caption;
					}
					if (area.Drawing) {
						$scope.modelDto.drawing = area.Drawing.Caption;
					}
					if (area.Person) {
						$scope.modelDto.cleaner = area.Person.Caption;
					}
					if (area.CleaningQuality) {
						$scope.modelDto.cleaningQuality = area.CleaningQuality.Caption;
					}
					if (area.ResourceGroup) {
						$scope.modelDto.cleaningTeam = area.ResourceGroup.Caption;
					}

					$scope.reload = true;
					$scope.reloads.deviations = true;
					$scope.reloads.controlLists = true;

					setTimeout(function () { $scope.activateAutocomplete = true; }, 250);
				},
				function (error) {
					repository.growl(error, 'danger');
				});
			getCardTables();
		} else {
			var getCategoryPrefill = function (guid) {
				var guidCategory = guid || $scope.model.GuidAreaCategory;
				if (!guidCategory) return;
				repository.getSingle(repository.apiData.areaCategory.url, guidCategory).then(function (result) {
					$scope.model.DynamicProperty = result.Data.DynamicProperty;
				}, function (error) {
					repository.growl(error, 'danger');
				});
			};

			var building = repository.commonService.getFilterData().selectedBuilding;
			if (building.Guid) {
				$scope.modelDto.building = building.Name;
				$scope.model.GuidBuilding = building.Guid;
			}

			var webMenuParams = repository.getMenuParameters($scope.navigation.params.menuGuid);
			if (webMenuParams && webMenuParams.Prefill) {
				webMenuParams.Prefill.forEach(function (prefill) {
					if (typeof (prefill.Value) === 'object') {
						switch (prefill.Property) {
							case 'Building':
								$scope.modelDto.building = prefill.Value.Label;
								$scope.model.GuidEstate = prefill.Value.Guid;
								break;
							case 'AreaCategory':
								$scope.model.GuidAreaCategory = prefill.Value.Guid;
								$scope.model.AreaCategory = { Guid: prefill.Value.Guid, Description: prefill.Value.Label, Caption: prefill.Value.Label };
								break;
							case 'CleaningQuality':
								$scope.modelDto.cleaningQuality = prefill.Value.Label;
								$scope.model.GuidCleaningQuality = prefill.Value.Guid;
								break;
							case 'ResourceGroup':
								$scope.modelDto.cleaningTeam = prefill.Value.Label;
								$scope.model.GuidCleaningTeam = prefill.Value.Guid;
								break;
							case 'Person':
								$scope.modelDto.cleaner = prefill.Value.Label;
								$scope.model.GuidCleaner = prefill.Value.Guid;
								break;
						}
					} else if (prefill.Value === '@CurrentBuilding') {
						if (building.Guid) {
							$scope.modelDto.building = building.Name;
							$scope.model.GuidBuilding = building.Guid;
						}
					} else {
						$scope.model[prefill.Property] = prefill.Value;
					}
				});
			}

			if ($stateParams.showPersistantData) {
				var persistentData = repository.persistedData.getPersistedData('area.create');

				if (persistentData) {
					$scope.model.GuidBuilding = persistentData.GuidBuilding;
					$scope.modelDto.building = persistentData.Building;
				}
			}
			getCategoryPrefill();
			$scope.activateAutocomplete = true;
		}

		$scope.updateArea = function (destination) {
			if (!$scope.model.ComputedGrossArea)
				$scope.model.ComputedGrossArea = "0";
			if (!$scope.model.ComputedNetArea)
				$scope.model.ComputedNetArea = "0";

			$scope.model.ComputedGrossArea = $scope.model.ComputedGrossArea.replace(',', '.');
			$scope.model.ComputedNetArea = $scope.model.ComputedNetArea.replace(',', '.');

			var success = function (result) {
				repository.growl($scope.update ? 'Rom har blitt oppdatert' : 'Rom har blitt lagret', 'success');
				var areaGuid = $scope.update ? $scope.model.Guid : result.Guid;

				repository.commonService.setLastRegisterGuid(destination, areaGuid);
				if ($scope.isModal && destination === 'area.edit')
					return;
				else if (destination === 'area.edit')
					$scope.navigation.go(destination, { guid: areaGuid }, { reload: true });
				else
					$scope.cancel();
			};

			var error = function (error) {
				if (typeof (error) === "string") {
					repository.growl(error, 'danger');
				} else {
					repository.growl(error.Data.Message, 'danger');
				}
			};

			if ($scope.update) {
				repository.updateSingleDictionary(repository.apiData.area.url, $scope.model).then(success).catch(error);
			} else {
				repository.createSingleDictionary(repository.apiData.area.url, $scope.model).then(success).catch(error);
			}
		};

		$scope.cancel = function () {
			if ($scope.isModal) {
				$modalStack.dismissAll();
			} else {
				$scope.goBack('area.list', { menuGuid: $scope.navigation.params.menuGuid });
			}
		};


		$scope.onAreaCategorySelect = function (category) {
			if (!category) {
				$scope.newDynamicProperty = null;
				$scope.model.GuidAreaCategory = "";
				return;
			}
			if (category.DynamicProperty && category.DynamicProperty.Groups.length > 0) {
				$scope.newDynamicProperty = category.DynamicProperty;
			} else {
				$scope.newDynamicProperty = null;
			}
			$scope.model.GuidAreaCategory = category.Guid;
		};

		$scope.removeArea = function () {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-area-message', "Rommet vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-area-button-confirm', 'Ja, fjern rommet!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.area.url, $scope.model.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-area-success', 'Rommet ble fjernet!'), result, "success");
						repository.growl('Rommet har blitt fjernet', 'success');
						$scope.goBack('area.list', { menuGuid: $scope.navigation.params.menuGuid });
					});
			});
		};

		$scope.onBuildingSelect = function(building) {
			if (!building) {
				$scope.model.GuidBuilding = "";
				return;
			}

			if ($scope.model.GuidBuilding !== building.Guid) {
				$scope.model.GuidDrawing = "";
				$scope.modelDto.drawing = null;
			}

			$scope.model.Address = building.Address;

			$scope.model.GuidBuilding = building.Guid;
		};

		$scope.onDrawingSelect = function(drawing) {
			if (!drawing) {
				$scope.model.GuidDrawing = "";
				$scope.modelDto.drawing = null;
				return;
			}

			$scope.model.GuidDrawing = drawing.Guid;
			$scope.model.GuidBuilding = drawing.GuidBuilding;
			$scope.modelDto.drawing = drawing.Caption;
		};

		$scope.getProgressType = function () {
			return ($scope.model.RealCapacity <= $scope.model.CalculatedCapacity) ? 'success' : 'danger';
		};

		$scope.addCleaningTask = function () {
			var modalInstance = $modal.open({
				templateUrl: 'app/cleaningTask/views/cleaningTaskWizardModal.html',
				controller: 'CleaningTaskWizardModalController',
				resolve: {
					modalParams: function () {
						return {
							columns: [
								{ Title: translationService.translate('web-cleaningTask-id'), Property: 'Id' },
								{ Title: translationService.translate('web-CleaningTask-estimatedtime'), Property: 'EstimatedTime' }
							],
							url: repository.apiData.cleaningTask.url,
							sorting: { Id: 'asc' },
							filter: {},
							area: $scope.model
						};
					}
				}
			});


			modalInstance.result.then(function (result) {
				var cleaningTask = result.cleaningTask, recurrenceRule = result.recurrenceRule, attributeValues = result.attributeValues, model = result.model;

				var areaXCleaningTasks = [JSON.stringify({
					GuidCleaningTask: cleaningTask.Guid,
					RecurrenceRule: recurrenceRule,
					GuidArea: $scope.model.Guid,
					AttributeValues: attributeValues,
					StartDate: model.StartDate ? new Date(model.StartDate).toISOString() : model.StartDate,
					UntilDate: model.UntilDate ? new Date(model.UntilDate).toISOString() : model.UntilDate
				})];

				repository.createMultiple(repository.apiData.areaXCleaningTask.url, areaXCleaningTasks).then(function (result) {
					repository.growl(result);
					$scope.cleaningTaskTable.reload();
				}, function (error) {
					repository.growl(error, 'danger');
				});
			}, function (error) {
			});
		};

		$scope.isPaused = function (task) {
			var today = moment().startOf('day');
			return task.PausedFromDate && task.PausedToDate && today.isBetween(task.PausedFromDate, task.PausedToDate, 'days', '[]');
		};

		$scope.setCleaningPauseDate = function (task) {
			$modal.open({
				templateUrl: 'app/area/views/editAreaXCleaningTaskStartDateModal.html',
				controller: 'AreaXCleaningTaskController',
				size: 'md',
				resolve: {
					modalParams: function () {
						return {
							Guid: task.Guid,
							SuggestedPause: {
								From: new Date(),
								To: new Date().setDate(new Date().getDate() + 1),
							}
						};
					}
				}
			}).result.then(function (result) {
				repository.growl(result, 'info');
				$scope.cleaningTaskTable.reload();
			});

		};

		$scope.editCleaningTask = function (task) {
			$modal.open({
				templateUrl: 'app/area/views/EditAreaXCleaningTaskModal.html',
				controller: 'AreaXCleaningTaskController',
				resolve: {
					modalParams: function () {
						return { Guid: task.Guid };
					}
				}
			}).result.then(function (result) {
				repository.growl(result, 'info');
				$scope.cleaningTaskTable.reload();
			});
		};

		$scope.removeCleaningTask = function (task) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-area-cleaningtask-message', "Renholdsoppgaven vil bli permanent fjernet!"),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-area-cleaningtask-button-confirm', 'Ja, fjern renholdsoppgaven'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;

				repository.deleteSingle(repository.apiData.areaXCleaningTask.url, '', 'guids=' + JSON.stringify([task.Guid]))
					.then(function (result) {
						swal(translationService.translate('web-swal-area-cleaningTask-success', 'Renholdsoppgaven ble fjernet!'), result, "success");
						repository.growl('Renholdsoppgaven har blitt fjernet', 'success');
						$scope.cleaningTaskTable.reload();
					});
			});
		};

		$scope.editComponent = function (component) {

			$modal.open({
				templateUrl: 'app/area/views/editComponentArea.html',
				controller: 'AreaComponentController',
				resolve: {
					params: function () { return { component: component, AddComponents: false }; }
				}
			}).result.then(function () {
				$scope.componentTable.reload();
				$scope.isComponentCollapsed = false;
			},
				function () {
					//cancel
					$scope.isComponentCollapsed = false;
				});
		};

		$scope.addComponents = function () {
			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Legg til komponent',
							url: repository.apiData.component.url,
							columns: [
								{ Title: translationService.translate('web-component-id'), Property: 'Id', PropertyType: 'string' },
								{ Title: translationService.translate('web-component-description'), Property: 'Description', PropertyType: 'string' },
							],
							useObjects: false
						};
					}
				}
			}).result.then(function (guids) {
				var model = [];

				guids.forEach(function (guid) {
					model.push({ GuidArea: $scope.model.Guid, GuidComponent: guid });
				});

				repository.createMultipleWithGuid(repository.apiData.componentArea.url, $scope.model.Guid, JSON.stringify(model)).then(function (result) {
					$scope.componentTable.reload();
				});
			},
				function () {
				});
		};

		$scope.removeComponent = function (component) {
			swal({
				title: translationService.translate('web-swal-error-areyousure', 'Er du sikker?'),
				text: translationService.translate('web-swal-equipment-component-message', 'Komponenten vil bli permanent fjernet!'),
				type: "warning",
				showCancelButton: true,
				confirmButtonColor: "#f44336",
				confirmButtonText: translationService.translate('web-swal-equipment-component-button-confirm', 'Ja, fjern komponenten!'),
				cancelButtonText: translationService.translate('web-button-cancel', 'Avbryt'),
				closeOnConfirm: false,
				showLoaderOnConfirm: true
			}, function () {
				window.onkeydown = null;
				window.onfocus = null;
				repository.deleteSingle(repository.apiData.componentArea.url, component.Guid)
					.then(function (result) {
						swal(translationService.translate('web-swal-equipment-component-success', 'Komponenten ble fjernet!'), result, "success");
						$scope.componentTable.reload();
					});
			});
		};


		$scope.createWorkOrder = function () {
			repository.persistedData.setPersistedData('workOrder.create',
				{
					guidEstate: $scope.model.Building.Estate.Guid,
					estate: $scope.model.Building.Estate.Caption,
					guidBuilding: $scope.model.GuidBuilding,
					building: $scope.model.Building ? $scope.model.Building.Caption : '',
					guidArea: $scope.model.Guid,
					area: $scope.model.Id + ' - ' + $scope.model.Description,
					fromArea: true
				});
			$scope.navigation.go('workOrder.create', { showPersistantData: true }, { reload: true });
		};

		$scope.addPerson = function () {
			var filter = {};
			var columns = [];

			columns.push({ Title: translationService.translate('web-area-person-firstName', 'Fornavn'), Property: 'FirstName' });
			columns.push({ Title: translationService.translate('web-area-person-lastName', 'Etternavn'), Property: 'LastName' });

			columns.push({ Title: translationService.translate('web-area-person-area-id', 'Areal Id'), Property: 'Area.Id' });
			columns.push({ Title: translationService.translate('web-area-person-area-description', 'Arealbeskrivelse'), Property: 'Area.Description' });

			$modal.open({
				templateUrl: 'app/common/views/multipleSelectModal.html',
				controller: 'MultipleSelectModalController',
				resolve: {
					modalParams: function () {
						return {
							title: 'Velg personer',
							columns: columns,
							useObjects: false,
							url: repository.apiData.person.url,
							sorting: { FirstName: 'asc' },
							filter: filter
						};
					}
				}
			}).result.then(function (selectedEntities) {
				if (!selectedEntities) return;

				var changeList = [];
				selectedEntities.forEach(function (entity) {
					changeList.push(JSON.stringify({ Guid: entity, GuidArea: $scope.model.Guid }));
				});

				repository.patch(repository.apiData.person.url, null, changeList)
					.then(function (result)
					{
						repository.growl(translationService.translate('web-area-person-connection-success', 'Personene har blitt lagt til arealet'), "success");
						$scope.reloads.persons = !$scope.reloads.persons;
					}, function (error) {
						repository.growl(error, "danger");
					});
			});
		};
	}
})();
