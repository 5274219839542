(function () {

	var app = angular.module('Plania');

	app.directive('projectEconomy', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				viewMode: '=viewMode',
				update: '=update',
				restrictEdit: '=restrictEdit',
				budget: '=budget',
				isModelLoading: '=isModelLoading',
				currentPage: '=currentPage'
			},
			controller: ['$scope', '$rootScope', 'Repository', 'TranslationService', '$modal', 'AutoCompleteService', 'ListService', 'ngTableParams', '$window', controller],
			templateUrl: 'app/project/views/projectEconomyDirective.html'
		};
	});

	function controller($scope, $rootScope, repository, translationService, $modal, autoCompleteService, listService, ngTableParams, $window) {
		$scope.rootScope = $rootScope;
		$scope.getPropertyValue = function (item, column) {
			return listService.GetPropertyValue(item, column);
		};
		$scope.hasVisitedTab = $scope.currentPage === 'economy';
		$scope.search = {};
		$scope.isRestOpen = $scope.model.RestExternalEstimatedAmountAfterOrder !== 0 && $scope.model.RestBudgetEstimatedAmount !== 0;
		if ($scope.model.IsTemplate) {
			$scope.periodicTaskPurchaseOrderItemColumns = [
				{ Position: 1, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-id', 'Id'), Property: 'Id', PropertyType: 'string' },
				{ Position: 2, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-description', 'Beskrivelse'), Property: 'Description', PropertyType: 'string' },
				{ Position: 3, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-explanatoryText', 'Tekst'), Property: 'ExplanatoryText', PropertyType: 'string' },
				{ Position: 4, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-orderedQuantity', 'Antall'), Property: 'OrderedQuantity', PropertyType: 'number' },
				{ Position: 5, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-unit', 'Enhet'), Property: 'Unit', PropertyType: 'string' },
				{ Position: 6, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-periodicTaskId', 'Periodisk Id'), Property: 'PeriodicTask.Id', PropertyType: 'string' },
				{ Position: 7, Title: translationService.translate('web-projectTemplate-periodicTask-purchaseOrderItem-updatedDate', 'Oppdatert'), Property: 'UpdatedDate', PropertyType: 'date' }
			];

			var periodicTaskPurchaseOrderFilter = {
				PropertyFilter: [{ Property: 'PeriodicTask.GuidProject', Operator: '=', Value: $scope.model.Guid }]
			};

			$scope.periodicTaskPurchaseOrderItemTable = new ngTableParams({
				page: 1,
				count: 50,
				sorting: { 'Id': 'asc' },
				filter: periodicTaskPurchaseOrderFilter
			}, {
					total: 0,
					filterDelay: 10,
					counts: [50, 100, 150],
					getData: function ($defer, params) {
						var columns = [];
						$scope.periodicTaskPurchaseOrderItemColumns.forEach(function (col) {
							columns.push(col.Property);
						});

						repository.GetPaginated(repository.apiData.purchaseOrderItem.url, params.page() - 1, params.count(),
							params.sorting(), params.filter(),
							'', JSON.stringify(columns)).then(function (result) {

								$scope.periodicTaskPurchaseOrderItemTable.settings().total = result.TotalCount;
								$scope.periodicTaskPurchaseOrderItemTable.settings().filterDelay = 500;
								$defer.resolve(result.List);
							}
							);
					}
				});
		}
		else {
			var purchaseOrderItemFilter = {
				PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }]
			};

			var getKpis = function () {
				var resourceGroupKpi = {
					Prefix: 'purchaseOrderItem',
					FromDate: new Date(0).toISOString(),
					ToDate: null,
					DateProperty: null,
					IncludePeriodicTask: false,
					Interval: null,
					PropertyFilter: purchaseOrderItemFilter.PropertyFilter,
					Properties: [{
						Property: 'OrderedAmount',
						Function: 'sum'
					}]
				};

				repository.createSingle(repository.apiData.kpi.url, resourceGroupKpi).then(function (result) {
					$scope.SumOrderedAmount = result[0].Result[0].Value;
				});
			};

			getKpis();

			$scope.$watch('purchaseOrderItemListSetup', function (newValue, oldValue) {
				if (oldValue === newValue)
					return;

				if ($scope.purchaseOrderItemTable) {
					$scope.purchaseOrderItemTable.$params.sorting = $scope.purchaseOrderItemListSetup.Sorting;

					if ($scope.purchaseOrderItemListSetup.GroupBy) {
						$scope.purchaseOrderItemTable.settings().groupBy = function (obj) {
							if (!$scope.purchaseOrderItemListSetup.GroupBy) {
								return false;
							}

							var attrs = $scope.purchaseOrderItemListSetup.GroupBy.split('.');
							attrs.forEach(function (attr) {
								if (!obj) {
									obj = "";
								} else {
									obj = obj[attr];
								}
							});

							return obj;
						};
					} else {
						$scope.purchaseOrderItemTable.settings().groupBy = null;
					}

					$scope.purchaseOrderItemTable.reload();
				}
			});

			listService.getWebListViews(null, repository.apiData.purchaseOrderItem.prefix, false, null, {}).then(function (result) {
				$scope.purchaseOrderItemListOptions = result.listOptions;
				$scope.purchaseOrderItemListSetup = result.selectedListSetup;

				//Purchase order item
				if ($scope.purchaseOrderItemTable) return;
				$scope.purchaseOrderItemTable = new ngTableParams({
					page: 1,
					count: 50,
					sorting: { 'Id': 'desc' },
					filter: { PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }] }
				},
					{
						total: 0,
						filterDelay: 10,
						counts: [50, 100, 150],
						getData: function ($defer, params) {
							var columns = [];
							$scope.purchaseOrderItemListSetup.Columns.forEach(function (col) {
								columns.push(col.Property);
							});

							repository.GetPaginated(repository.apiData.purchaseOrderItem.url,
								params.page() - 1,
								params.count(),
								params.sorting(),
								params.filter(),
								'',
								JSON.stringify(columns))
								.then(function (result) {
									$scope.purchaseOrderItemTable.settings().total = result.TotalCount;
									$scope.purchaseOrderItemTable.settings().filterDelay = 500;
									$defer.resolve(result.List);
								}
								);
						}
					});
			});



			$scope.costInfo = new function () {
				var me = this;
				this.apiData = repository.apiData.cost;
				this.jsPrefix = _.camelCase(this.apiData.prefix);

				this.canCreate = function () {
					return !$scope.restrictEdit && $scope.rootScope.hasCreateAccess(this.apiData.prefix);
				};

				this.initTable = function () {
					if (me.table)
						return;
					me.table = new ngTableParams({
						page: 1,
						count: 50,
						sorting: { 'UpdatedDate': 'desc' },
						filter: { PropertyFilter: [{ Property: 'WorkOrder.GuidProject', Operator: '=', Value: $scope.model.Guid }] }
					},
						{
							total: 0,
							filterDelay: 10,
							counts: [10, 20, 50],
							getData: function ($defer, params) {
								var columns = [];
								me.listSetup.Columns.forEach(function (col) {
									columns.push(col.Property);
								});
								repository.GetPaginated(me.apiData.url,
									params.page() - 1,
									params.count(),
									params.sorting(),
									params.filter(),
									null,
									JSON.stringify(columns)).then(
										function (result) {
											me.table.settings().total = result.TotalCount;
											me.table.settings().filterDelay = 500;
											$defer.resolve(result.List);
										},
										function (error) {
											repository.growl(error, 'danger');
										});
							}
						});
				};

				this.create = function () {
					if (!me.canCreate())
						return;

					var persistedData = {};
					persistedData.GuidProject = $scope.model.Guid;
					repository.persistedData.setPersistedData(me.jsPrefix + '.create', persistedData);
					$scope.$parent.navigation.go(me.jsPrefix + '.create', { showPersistantData: true }, { reload: true });
				};

				this.update = function (item, event) {
					$scope.$root.navigation.go('cost.edit', { guid: item.Guid });
				};

				listService.getWebListViews(null, this.apiData.prefix, false, null, {}).then(function (result) {
					me.listOptions = result.listOptions;
					me.listSetup = result.selectedListSetup;

					$scope.costInfo.initTable();
				});
			}();
		}

		$scope.updatePurchaseOrderItem = function (purchaseOrderItem) {
			$modal.open({
				templateUrl: 'app/purchaseOrder/views/purchaseOrderItemModal.html',
				controller: 'PurchaseOrderItemModalController',
				size: 'xl',
				resolve: {
					params: function () {
						return {
							Guid: purchaseOrderItem.Guid
						};
					}
				}
			}).result.then(function (result) {

				$scope.model.EstimatedCost = $scope.model.EstimatedCost ? $scope.model.EstimatedCost + result.estimatedCost - (purchaseOrderItem.UnitPrice * purchaseOrderItem.OrderedQuantity) : result.estimatedCost;
				$scope.purchaseOrderItemTable.reload();
			},
				function () {
					//cancel
				});
		};


		$scope.showPurchaseOrder = function (item) {
			$scope.rootScope.navigation.go('purchaseOrder.edit', { guid: item.GuidPurchaseOrder }, { reload: true });

		};

		$scope.showWorkOrder = function (item) {
			$scope.rootScope.navigation.go('workOrder.edit', { guid: item.GuidWorkOrder }, { reload: true });

		};

		var getMenuLink = function (url, icon, text) {
			return {
				html: '<a class="dropdown-item" tabindex= "-1" href="' + url + '" ><i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ' + text + '</a>',
				isHref: true
			};
		};

		$scope.menuOptions = function (purchaseOrderItem) {
			var options = [];

			if ($rootScope.hasEditAccess('PurchaseOrderItem')) {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-edit zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-project-purchaseOrderItem-edit', 'Rediger konteringsobjekt') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						$scope.updatePurchaseOrderItem(purchaseOrderItem);
					}
				});
			}

			if (purchaseOrderItem.GuidPurchaseOrder && repository.enableWebNavigation) {
				options.push(getMenuLink($rootScope.navigation.href('purchaseOrder.edit', { guid: purchaseOrderItem.GuidPurchaseOrder }), 'zmdi-shopping-cart', translationService.translate('web-project-open-purchaseorder', 'Åpne bestilling')));
			}

			if (purchaseOrderItem.GuidWorkOrder && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: purchaseOrderItem.GuidWorkOrder }), 'zmdi-case', translationService.translate('web-project-open-workorder', 'Åpne arbeidsordre')));

			if (purchaseOrderItem.GuidPeriodicTask && repository.enableWebNavigation)
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: purchaseOrderItem.GuidPeriodicTask }), 'zmdi-calendar-note', translationService.translate('web-project-open-periodicTask', 'Åpne periodisk rutine')));

			if ($scope.rootScope.hasCreateAccess('Cost') &&
				purchaseOrderItem.Status !== 'Proposal' &&
				purchaseOrderItem.Status !== 'Closed') {
				options.push({
					html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i class="zmdi zmdi-receipt zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-workorder-purchaseorderitem-createCost', 'Legg til kostnad') + '</a >',
					click: function ($itemScope, $event, modelValue, text, $li) {
						var persistedData = {};
						persistedData.GuidPurchaseOrderItem = purchaseOrderItem.Guid;
						repository.persistedData.setPersistedData('cost.create', persistedData);
						$scope.$parent.navigation.go('cost.create', { showPersistantData: true }, { reload: true });
					}
				});
			}
			return options;
		};

		// Watchers
		var loadingListener = $scope.$watchGroup(['isModelLoading'],
			function () {
				if ($scope.isModelLoading === false) {
					$scope.telerikInfo.updateTelerikParameters();
					loadingListener(); // Clear watcher
				}
			}, true);

		$scope.$watch('currentPage', function (newVal, oldVal) {
			if (newVal === oldVal) return;

			if (newVal === 'economy') {
				$scope.telerikInfo.reloadReports = !$scope.telerikInfo.reloadReports;
				$scope.hasVisitedTab = true;
			}
		});


		// Infos
		$scope.telerikInfo = new function () {
			var me = this;
			this.parameters = { GuidProject: $scope.model.Guid };
			this.displayReports = false;
			this.endDate = new Date();
			this.reloadReports = false;

			this.updateTelerikParameters = function () {
				if ($scope.model.IsTemplate) return;
				var endDate = $scope.model.EndDate ? new Date($scope.model.EndDate) : null;
				var startDate = $scope.model.StartDate ? new Date($scope.model.StartDate) : new Date();
				if (endDate === null || endDate === undefined) {
					endDate = $scope.telerikInfo.endDate;
				}

				if (endDate.getTime() < startDate.getTime())
					endDate = $scope.model.StartDate;

				$scope.telerikInfo.parameters.StartDate = startDate;
				$scope.telerikInfo.parameters.EndDate = endDate;
				$scope.telerikInfo.displayReports = true;
			};
		}();
	}
})();
