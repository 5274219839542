(function () {
	angular.module('Plania').controller('SelectedSelectionController', ['$scope', '$rootScope', 'Repository', 'CommonService', 'TranslationService', controller]);

	function controller($scope, $rootScope, repository, commonService, translationService) {
		$scope.count = 20;// all
		$scope.totalCount = 0;
		$scope.selections = [];

		$scope.changeSelectionAccess = $scope.hasReadAccess(repository.commonService.prefix.BuildingSelection);
		$scope.selectedSelection = {};

		var storedSelection = commonService.getFilterData().selectedSelection;
		if (storedSelection)
			$scope.selectedSelection = storedSelection;

		$scope.filter = {
			IncludeGuid: $scope.selectedSelection.Guid
		};

		$scope.getSelections = function () {
			if ($scope.changeSelectionAccess) {
				repository.GetPaginated(repository.apiData.buildingSelection.url, 0, $scope.count, { description: "asc" }, $scope.filter, '', JSON.stringify(["Description"])).then(function (result) {
					if (result) {
						$scope.totalCount = result.TotalCount;
						$scope.selections = result.List;
					}

					var storedSelection = commonService.getFilterData().selectedSelection;

					if (storedSelection.Guid !== '' && $scope.selectedSelection && storedSelection.Guid !== $scope.selectedSelection.Guid) {
						$rootScope.$broadcast($rootScope.events.newSelection, true);
					}

					// TODO - Check if needed after change where we get Description from userData.
					if ($scope.selectedSelection && !$scope.selectedSelection.Description)
						$scope.selectedSelection.Description = _.filter($scope.selections, { Guid: storedSelection.Guid })[0].Description;

				}, function (error) {
					$scope.totalCount = 0;
					$scope.selections = [];
					repository.growl(error, 'danger');
				});
			}
		};

		$scope.getSelections();

		$scope.addMoreData = function () {
			$scope.count += 100;
			$scope.getSelections();
		};

		$scope.changeSelection = function (selected) {
			if (selected.Guid === $scope.selectedSelection.Guid) {
				return;
			}

			commonService.setSelectedSelection(selected);
			commonService.setEmptyBuilding();
			$rootScope.$broadcast($rootScope.events.newSelection, true);
		};

		$scope.setEmptySelection = function () {
			$scope.filter.searchString = '';

			if (!$scope.selectedSelection.Guid) {
				return;
			}
			commonService.setEmptySelection();
			$rootScope.$broadcast($rootScope.events.newSelection, true);
		};

		$scope.$on($scope.events.newSelection, function () {
			$scope.selectedSelection = commonService.getFilterData().selectedSelection;
		});

		$scope.$on($scope.events.updatedAccess, function () {
			$scope.changeSelectionAccess = $scope.hasReadAccess(repository.commonService.prefix.BuildingSelection);

			if (!$scope.changeSelectionAccess) {
				commonService.setSelectedSelection({ Guid: repository.authService.getUserData().defaultSelection, Description: repository.authService.getUserData().defaultSelectionDescription });
				$rootScope.$broadcast($rootScope.events.newSelection, true);
			}
			if ($scope.selectedSelection.Guid === "")
				$scope.getSelections();
		});
	}
})();
