(function () {
	var app = angular.module('Plania');
	app.directive('controlListHistoryDirective', function () {
		return {
			restrict: 'E',
			scope: {
				model: '=ngModel',
				restrictEdit: '=restrictEdit',
				restrictDelete: '=restrictDelete',
				reload: '=reload',
				isUpdate: '=isUpdate',
				activateAutocomplete: '=activateAutocomplete',
				currentTab: '=currentTab'
			},
			controller: ['$scope', 'ngTableParams', 'Repository', 'TranslationService', '$modal', '$rootScope', 'ListService', '$timeout', controller],
			templateUrl: 'app/controlList/views/editControlListViews/controlListHistory.html',
		};
	});

	function controller($scope, ngTableParams, repository, translationService, $modal, $rootScope, listService, $timeout) {
		$scope.hasReadAccess = $rootScope.hasReadAccess;
		$scope.hasDeleteAccess = $rootScope.hasDeleteAccess;
		$scope.hasCreateAccess = $rootScope.hasCreateAccess;
		$scope.hasModule = $rootScope.hasModule;
		$scope.controlListXEntitySearch = {
			focus: false,
			toggle: false
		};

		$scope.hasPageLoaded = $scope.currentTab === 'history';
		if (!$scope.hasPageLoaded) {
			var pageListener = $scope.$watch('currentTab', function (newVal, oldVal) {
				if (newVal === oldVal) return;
				$timeout(function () {
					if (newVal === 'history' && !$scope.hasPageLoaded) {
						$scope.hasPageLoaded = true;
						pageListener();
					}
				}, 250);
			});
		}

		var filterTypes = [
			{ value: 'all', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-all', 'Alle') },
			{ value: 'new', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-new', 'Nye') },
			{ value: 'active', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-active', 'Aktive') },
			{ value: 'completed', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-completed', 'Fullførte') },
			{ value: 'notexecuted', name: translationService.translate('web-controlList-historyTab-controlListXEntityTable-notExecuted', 'Ikke utført') }
		];

		$scope.currentSelectedFilterType = filterTypes[0];

		$scope.setFilter = function (filterTypeValue) {
			var selectedFilterType = _.find(filterTypes, { value: filterTypeValue });
			if (selectedFilterType && filterTypeValue !== $scope.currentSelectedFilterType.value) {
				$scope.currentSelectedFilterType = selectedFilterType;
				$scope.controlListXEntityTable.reload();
			}
		};

		$scope.controlListXEntityColumns = [
			{ Position: 1, Title: translationService.translate('web-controlList-historyTab-workOrder-caption', 'Arbeidsordre'), Property: "WorkOrder.Id", PropertyType: "string", isCaption: true },
			{ Position: 2, Title: translationService.translate('web-controlList-historyTab-relatedEntity-caption', 'Tilknyttet objekt'), PropertyType: "string", isComplex: true, SortingDisabled: true },
			{ Position: 3, Title: translationService.translate('web-controlList-historyTab-status', 'Status'), Property: "Status", PropertyType: "enum.ControlListXEntityStatus" },
			{ Position: 4, Title: translationService.translate('web-controlList-historyTab-user-realName', 'Fullført av'), Property: "User.RealName", PropertyType: "string" },
			{ Position: 5, Title: translationService.translate('web-controlList-historyTab-controlListXEntity-closedDate', 'Fullført'), Property: "ClosedDate", PropertyType: "date" },
			{ Position: 6, Title: translationService.translate('web-controlList-historyTab-controlList-version', 'Versjon'), Property: "ControlList.Version", PropertyType: "string" },
		];

		if ($scope.isUpdate) {
			$scope.controlListXEntityTable = new ngTableParams({
				page: 1,
				count: 10,
				sorting: { 'ClosedDate': 'desc' },
				filter: { PropertyFilter: [{ Property: 'ControlList.GuidMasterRecord', Operator: '=', Value: $scope.model.Guid, Operand: "OR" }, { Property: 'ControlList.Guid', Operator: '=', Value: $scope.model.Guid, Operand: "OR" }] }
			}, {
				total: 0,
				counts: [10, 20, 50],
				filterDelay: 10,
				getData: function ($defer, params) {
					var columns = [];
					$scope.controlListXEntityColumns.forEach(function (col) {
						if (col.Property)
							columns.push(col.Property);
					});

					columns.push("WorkOrder.Description");
					columns.push("Equipment.Id");
					columns.push("Equipment.Description");
					columns.push("Area.Id");
					columns.push("Area.Description");
					columns.push("Area.IsHousing");
					columns.push("PeriodicTask.Id");
					columns.push("PeriodicTask.Description");

					var filter = angular.copy(params.filter());

					switch ($scope.currentSelectedFilterType.value) {
						case 'all':
							break;
						case 'new':
							filter.PropertyFilter.push({ Property: 'Status', Operator: '=', Value: '0' });
							break;
						case 'active':
							filter.PropertyFilter.push({ Property: 'Status', Operator: '=', Value: '1' });
							break;
						case 'completed':
							filter.PropertyFilter.push({ Property: 'Status', Operator: '=', Value: '2' });
							break;
						case 'notexecuted':
							filter.PropertyFilter.push({ Property: 'Status', Operator: '=', Value: '3' });
							break;
					}

					repository.GetPaginated(repository.apiData.controlListXEntity.url, params.page() - 1, params.count(), params.sorting(), filter, "", JSON.stringify(columns)).then(function (result) {
						$scope.controlListXEntityTable.settings().total = result.TotalCount;
						$scope.controlListXEntityTable.settings().filterDelay = 500;
						$defer.resolve(result.List);
					}, function (error) {
						repository.growl(error, 'danger');
					});
				}
			});
		}

		var getLowestEntityCaption = function (entity) {
			if (entity.Equipment) return entity.Equipment.Caption;
			if (entity.Area) return entity.Area.Caption;

			if (entity.PeriodicTask) {
				return translationService.translate('web-controlListXEntity-directConnectionPeriodicTask', 'Direkte tilkoblet rutine');
			} else {
				return translationService.translate('web-controlListXEntity-directConnectionWorkOrder', 'Direkte tilkoblet arbeidsordre');
			}
		};

		var getEntityIcon = function (entity) {
			if (entity.Equipment) return 'zmdi-settings c-bluegray';
			if (entity.Area) return 'zmdi-layers c-lightblue';
		};

		$scope.getPropertyValue = function (item, column) {
			if (column.isComplex) {
				var icon = '<i class="zmdi zmdi-hc-lg ' + getEntityIcon(item) + '"></i> ';
				var span = '<span>' + getLowestEntityCaption(item) + '</span>';
				return '<span>' + icon + span + '</span>';
			}

			if (column.isCaption) {
				var newColumn = {
					Position: column.Position,
					Property: column.Property.replace(".Id", ".Caption"),
					PropertyType: column.PropertyType,
					Title: column.Title
				};
				return listService.GetPropertyValue(item, newColumn);
			}

			return listService.GetPropertyValue(item, column);
		};

		$scope.openControlList = function (item) {
			$modal.open({
				templateUrl: 'app/controlList/views/controlListCompletionModal.html',
				size: 'lg',
				controller: 'ControlListCompletionModalController',
				resolve: {
					params: function () {
						return {
							workOrderCaption: item.WorkOrder.Caption,
							controlList: $scope.model,
							controlListXEntity: item,
							restrictEdit: true
						};
					}
				}
			});
		};

		var getMenuLink = function (url, text, icon) {
			var htmlText = '<a class="dropdown-item" tabindex= "-1" href="' + url + '" >';
			// Show icon, else draw a random icon to keep same padding on left.
			if (icon)
				htmlText += '<i class="zmdi ' + icon + ' zmdi-hc-fw zmdi-hc-lg"></i> ';
			else
				htmlText += '<i style="visibility:hidden;" class="zmdi zmdi-case zmdi-hc-fw zmdi-hc-lg"></i> ';
			htmlText += text + '</a>';

			return {
				html: htmlText,
				isHref: true
			};
		};

		$scope.controlListXEntityContextMenuOptions = function (item) {
			var options = [];

			options.push({
				html: '<a class="dropdown-item" tabindex= "-1" href= "#"><i style="visibility:hidden;" class="zmdi zmdi-delete zmdi-hc-fw zmdi-hc-lg"></i> ' + translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-openControlList', 'Åpne kontrolliste') + '</a >',
				click: function ($itemScope, $event, modelValue, text, $li) {
					$scope.openControlList(item);
				}
			});

			if ($scope.hasReadAccess("WorkOrder") && item.GuidWorkOrder) {
				options.push(getMenuLink($rootScope.navigation.href('workOrder.edit', { guid: item.GuidWorkOrder }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-workOrder-view', 'Åpne arbeidsordre')));
			}

			if ($scope.hasReadAccess("PeriodicTask") && item.GuidPeriodicTask) {
				options.push(getMenuLink($rootScope.navigation.href('periodicTask.edit', { guid: item.GuidPeriodicTask }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-periodicTask-view', 'Åpne periodisk rutine')));
			}

			if ($scope.hasReadAccess("Area") && item.GuidArea) {
				if (item.Area.IsHousing)
					options.push(getMenuLink($rootScope.navigation.href('housing.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-housing-view', 'Åpne bolig')));
				else
					options.push(getMenuLink($rootScope.navigation.href('area.edit', { guid: item.GuidArea }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-area-view', 'Åpne rom')));
			}

			if ($scope.hasReadAccess("Equipment") && item.GuidEquipment) {
				options.push(getMenuLink($rootScope.navigation.href('equipment.edit', { guid: item.GuidEquipment }), translationService.translate('web-controlList-historyTab-controlListXEntityContextMenuOptions-equipment-view', 'Åpne anlegg')));
			}

			return options;
		};
	}
})();
