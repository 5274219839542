(function () {
	angular.module('Plania').controller('PartiallyReceiveItemController', ['$scope', '$modalInstance', 'params', 'Repository', 'TranslationService', 'EnumService', '$state', '$rootScope', controller]);

	function controller($scope, $modalInstance, params, repository, translationService, enumService,  $state, $rootScope) {
		$scope.model = {};
		$scope.modelProperties = { quantityToReceive: 1 };
		$scope.maxQuantityToReceive = 0;
		var columns = [
			'SparePart.Inventory'
			
		];

		$scope.units = enumService.unit;

		$scope.getUnitValue = function (value) {
			var unit = _.find($scope.units, function (o) { return o.value === value; });

			if (unit)
				return unit.translatedValue;
			else
				return value;
		};

		repository.getMainModel(repository.apiData.purchaseOrderItem.url, params.Guid, JSON.stringify(columns)).then(
			function (response) {
				$scope.model = response.Data;
				$scope.maxQuantityToReceive = $scope.model.Rest;
				$scope.modelProperties.quantityToReceive = $scope.model.Rest;
			},
			function (error) {
				repository.growl(error, 'danger');
				
			}
		);

		$scope.save = function () {

			$scope.model.ReceivedQuantity = $scope.model.ReceivedQuantity + $scope.modelProperties.quantityToReceive;
			$scope.model.Rest = $scope.model.OrderedQuantity - $scope.model.ReceivedQuantity;

			$modalInstance.close($scope.model);
		};

		$scope.cancel = function () {
			$modalInstance.close();
		};
	}
})();
